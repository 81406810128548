/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

export const GET_USER_INFO = 'PROFILE/GET_USER_INFO';
export const SAVE_USER_INFO = 'PROFILE/SAVE_USER_INFO';
export const CHANGE_USER_PASS = 'PROFILE/CHANGE_USER_PASS';
export const GET_USERS_ONLINE = 'PROFILE/GET_USERS_ONLINE';
export const CHANGE_USER_COUNSELOR = 'PROFILE/CHANGE_USER_COUNSELOR';
