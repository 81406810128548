import React from 'react';
import socketio from 'socket.io-client';
import { SOCKET_URL } from 'utils/enviroment';
import * as storage from 'utils/storage';

const token = storage.getItem('token');
/*
  const role = storage.getItem('persist:auth')
  && (storage.getItem('persist:auth').user).replace(/['"]+/g, '');
*/

export const socket = socketio.connect(SOCKET_URL, {
  query: `token=${token}&role=`,
});

export const SocketContext = React.createContext();
