/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Utils
import {
  validateMsgParent,
  dropdownMessage,
  handleRenderTranslation,
  scrollToBottom,
} from 'utils/helpers';

// Assets
import { BsCheck, BsCheckAll } from 'react-icons/bs';

export default function BodyChat({
  messages,
  globalActions,
  setOptionMessage,
  optionMessage,
  handleRenderMessageContent,
  roomId,
  visitorId,
  page,
  totalPages,
  messageActions,
}) {
  const { t } = useTranslation();
  const boxRef = useRef(null);
  const [isCheckStatus, setIsCheckStatus] = useState(false);

  useEffect(() => {
    if (messages.length && page === 1 && !isCheckStatus) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  const handleScroll = () => {
    if (boxRef.current.scrollTop === 0) {
      if (page < totalPages) messageActions.getMessages(page + 1, roomId);
    }
  };

  const handleClickInside = e => {
    if (boxRef.current) {
      if (boxRef.current.contains(e.target)) {
        messageActions.checkVisitorMessages(
          visitorId,
          roomId,
          (type, response) => {
            if (type === 'success') {
              messageActions.getMessages(page, roomId);
            }
          },
        );
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickInside);
    return () => document.removeEventListener('mousedown', handleClickInside);
  });

  useEffect(() => {
    messageActions.checkVisitorMessages(
      visitorId,
      roomId,
      (type, response) => {
        if (type === 'success') {
          messageActions.getMessages(page, roomId);
        }
      },
    );
  }, [optionMessage]);

  useEffect(() => {
    if (!isCheckStatus) {
      messageActions.checkVisitorMessages(
        visitorId,
        roomId,
        (type, response) => {
          if (type === 'success') {
            setIsCheckStatus(true);
            messageActions.getMessages(page, roomId);
          }
        },
      );
    }
  }, [messages]);

  setTimeout(() => {
    if (isCheckStatus) {
      setIsCheckStatus(false);
    }
  }, 2000);

  return (
    <div
      id="messages"
      className={`flex flex-col space-y-4 p-5 overflow-y-auto h-full ${
        optionMessage ? 'content-chat-scroll-quote' : 'content-chat-scroll'
      }`}
      ref={boxRef}
      onScroll={handleScroll}
    >
      {messages.length > 0
        && messages.map((item, index) => (
          <div key={index} className="chat-message">
            <div
              className={`flex items-end ${
                !item.visitor_id ? 'justify-end' : 'justify-start'
              }`}
            >
              <div
                className={`flex flex-col space-y-2 text-xs max-w-xs mx-2 ${
                  !item.visitor_id ? 'order-2 items-start' : 'order-1 items-end'
                }`}
              >
                <div>
                  <span
                    className={`flex px-2 flex-col items-center py-2 rounded-lg break-all ${
                      item.visitor_id
                        ? 'bg-gray-200 text-gray-600 rounded-bl-none'
                        : 'bg-blue-400 text-white rounded-br-none'
                    }`}
                  >
                    <div
                      className={`px-2 py-2 rounded-lg w-full ${
                        validateMsgParent(item, handleRenderTranslation)
                        === null
                          ? 'hidden'
                          : 'bg-red-300'
                      }`}
                    >
                      {validateMsgParent(item, handleRenderTranslation)}
                    </div>
                    <div className="flex-row pt-1 pb-1 flex justify-center items-center">
                      <div className="mr-4">
                        {dropdownMessage(
                          item,
                          globalActions,
                          setOptionMessage,
                          t('dropdown-message-op-1'),
                          t('dropdown-message-op-2'),
                        )}
                      </div>
                      {handleRenderMessageContent(item)}
                    </div>
                  </span>
                </div>
                <div
                  className={`text-gray-300 flex w-full ${
                    item.visitor_id ? 'justify-start' : 'justify-end'
                  }`}
                >
                  <div className="flex justify-between">
                    {moment(item.created_at).format('LT')}
                    <div className="ml-2">
                      {!item.check ? (
                        <BsCheck size={20} />
                      ) : (
                        <BsCheckAll size={20} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

BodyChat.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
  globalActions: PropTypes.objectOf(PropTypes.any).isRequired,
  setOptionMessage: PropTypes.func.isRequired,
  optionMessage: PropTypes.bool.isRequired,
  handleRenderMessageContent: PropTypes.func.isRequired,
  roomId: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  visitorId: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
  messageActions: PropTypes.shape({
    getMessages: PropTypes.func,
    checkVisitorMessages: PropTypes.func,
  }).isRequired,
};
