/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

export const HOST = process.env.REACT_APP_HOST;
export const VERSION = process.env.REACT_APP_VERSION;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const TRANSLATE_API_KEY = process.env.REACT_APP_TRANSLATE_API_KEY;
export const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY;

export const AWS_CONFIG = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: process.env.REACT_APP_S3_DIR,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  s3Url: process.env.REACT_APP_S3_URL,
};
