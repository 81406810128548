/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

/**
 * @file public.js
 * @author Daniel Felipe Lucumi Marin
 * @description Public routes.
 */

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (<Component {...props} />)}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.elementType,
};

/**
 * Default proptypes.
 */
PublicRoute.defaultProps = {
  component: {},
};

export default PublicRoute;
