/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

export const SEND_MESSAGE = 'MESSAGES/SEND_MESSAGE';
export const GET_MESSAGES = 'MESSAGES/GET_MESSAGES';
export const RENDER_MESSAGES = 'MESSAGES/RENDER_MESSAGES';
export const ADD_MESSAGE = 'MESSAGES/ADD_MESSAGE';
export const CHECK_VISITOR_MESSAGES = 'MESSAGES/CHECK_VISITOR_MESSAGES';
export const RESET_CHAT = 'MESSAGES/RESET_PAGINATION';
