/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import { put, call, takeLatest } from 'redux-saga/effects';

// Redux
import { setResponse } from 'redux/stores/app/actions';

// Utils
import request from 'utils/request';

// Constants
import { GET_MY_ROOMS, HIDE_ROOM, SELECTED_ROOM } from './constants';

/**
* @file saga.js
* @author Daniel Felipe Lucumi Marin
* @description Execute request
*/

function* hideRoom(action) {
  const {
    cb,
    payload,
  } = action;
  const url = '/room/hide';
  const requestOptions = { method: 'PUT', body: JSON.stringify(payload) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* getMyRooms(action) {
  const {
    cb,
  } = action;
  const url = '/room/my-rooms';
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* getSelectedRooms(action) {
  const {
    cb,
    payload,
  } = action;
  const url = `/room/get-room/${payload}`;
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

/**
* Declare all component sagas
*/

export default function* rootSaga() {
  yield takeLatest(GET_MY_ROOMS, getMyRooms);
  yield takeLatest(HIDE_ROOM, hideRoom);
  yield takeLatest(SELECTED_ROOM, getSelectedRooms);
}
