/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
/* eslint-disable no-alert */
/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import React from 'react';
import { toast } from 'react-toastify';
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment';
import S3 from 'react-aws-s3';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import QRCode from 'qrcode';
import swal from 'sweetalert';
import handleMessage from 'containers/chat/handleMessage';
import { Dropdown } from 'semantic-ui-react';
import { MdCloudDownload } from 'react-icons/md';
import { AWS_CONFIG } from './enviroment';

/**
 * @author Brayan David Coy
 * @file helpers.js
 * @description Execute request
 */

/**
 *
 * @param {*} String message
 * @returns Error html element
 */

const config = AWS_CONFIG;
const ReactS3Client = new S3(config);

export const renderError = message => (
  <div className="w-full text-left text-red-500">{message}</div>
);

export const renderFlag = code => (
  <img
    width="24"
    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}
    alt={code}
  />
);

export const getQRcode = async response => {
  let generated;
  if (!response.type) {
    const images = await QRCode.toDataURL(response.qr);
    generated = (
      <div>
        <span>Connect whatsapp</span>
        <img src={images} alt="QR" className="w-full" />
      </div>
    );
  } else {
    generated = <span>{response.msg}</span>;
  }

  return generated;
};

export function tryParseJSON(jsonString) {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.log(e);
  }
  return false;
}

export const getNotificationsPermissions = () => {
  if (!('Notification' in window)) {
    alert('This browser does not support system notifications');
    return false;
  }
  if (Notification.permission === 'granted') {
    return true;
  }
  if (Notification.permission !== 'denied') {
    Notification.requestPermission(permission => {
      if (permission === 'granted') {
        return true;
      }
      return false;
    });
  }
  return false;
};

export const AlertNotification = (type, message) => {
  toast[type](message, {
    position: 'top-right',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const generateMessagesPDF = (
  tickets,
  action,
  visitorId,
  userId,
  roomId,
) => {
  const doc = new jsPDF();
  const tableColumn = ['Body', 'Lang', 'Trans', 'Date'];
  const tableRows = [];

  tickets.forEach(ticket => {
    const ticketData = [
      ticket.content,
      ticket.lang,
      ticket.translation,
      moment(ticket.created_at).format('DD-MM-YYYY'),
    ];
    tableRows.push(ticketData);
  });

  doc.autoTable(tableColumn, tableRows, { startY: 20 });
  const date = Date().split(' ');
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  doc.text('Your messages with the counselor.', 14, 15);

  const blob = doc.output('blob');
  const file = new File([blob], date.valueOf(), {
    type: 'application/pdf',
  });
  const newFileName = `report_${dateStr}`;
  doc.save(newFileName);
  ReactS3Client.uploadFile(file, newFileName).then(data => {
    if (data.status === 204) {
      action(visitorId, userId, roomId, data.location);
    }
  });
};

export const swalAlert = () => swal({
  title: 'Are you sure?',
  text: 'Confirm the action!',
  icon: 'warning',
  buttons: true,
}).then(willAction => {
  if (willAction) {
    swal('Action success', {
      icon: 'success',
    });
    return true;
  }
  swal('Close!');
  return false;
});

export const handleLoadShortcut = (
  input,
  getShortcodes,
  pageShortcode,
  setShortCode,
  setShortcutView,
) => {
  if (input.includes('@')) {
    const text = input.replace('@', '');
    getShortcodes(pageShortcode, text, (type, data) => {
      if (type === 'success') {
        if (Object.keys(data).length > 0) {
          setShortCode(data);
          setShortcutView(true);
        }
      }
    });
  }
};

export const handleRenderTranslation = item => {
  const { content, translation, lang } = item;
  const myLang = navigator.language.split('-')[0];
  if (myLang === lang) {
    return translation;
  }
  return content;
};

export const validateMsgParent = (item, translateFn) => {
  if (!item.replay) {
    return null;
  }
  switch (item.replay.type) {
    case 'Image':
      return (
        <img
          src={item.replay.content}
          className="border-2 rounded-lg"
          width="80%"
          alt="message-img"
        />
      );
    case 'Audio':
      return (
        <ReactAudioPlayer
          src={item.replay.content}
          autoPlay={false}
          controls
          style={{ width: 150, height: 50 }}
        />
      );
    case 'Text':
      return <h1>{translateFn(item.replay)}</h1>;
    case 'File':
      return (
        <a
          className="flex flex-col items-center"
          href={item.replay.content}
          target="_blank"
          download
          rel="noreferrer"
        >
          <MdCloudDownload size={30} />
          <h6>Download</h6>
        </a>
      );
    default:
      return true;
  }
};

export const uploadFileIntoChat = (
  e,
  id,
  messageActions,
  visitorLang,
  setValue,
  messages,
) => {
  if (e.target.files[0]) {
    let messageResponse = {};
    const file = e.target.files[0];
    const newFileName = e.target.files[0].name;
    const isImage = file.type.includes('image');
    ReactS3Client.uploadFile(file, newFileName).then(data => {
      if (data.status === 204) {
        if (!isImage) {
          messageResponse = handleMessage('File', data.location, id);
        } else {
          messageResponse = handleMessage('Image', data.location, id);
        }
        messageActions.sendMessage(messageResponse, visitorLang, status => {
          if (status === 'success') {
            setValue('');
            messageActions.renderMessages([...messages, messageResponse]);
          }
        });
      }
    });
  }
};

export const dropdownMessage = (
  item,
  globalActions,
  setOptionMessage,
  textCopy,
  textQuote,
) => (
  <Dropdown upward floating>
    <Dropdown.Menu>
      <Dropdown.Item
        name="copy"
        text={textCopy}
        onClick={() => navigator.clipboard.writeText(handleRenderTranslation(item))}
      />
      <Dropdown.Item
        name="quote"
        text={textQuote}
        onClick={() => {
          globalActions.setReplayMessage(item);
          setOptionMessage(true);
        }}
      />
    </Dropdown.Menu>
  </Dropdown>
);

export const scrollToBottom = () => {
  const messageChat = document.getElementById('messages');
  messageChat.scrollTop = messageChat.scrollHeight;
};

export const handleResetChat = (id, roomId, resetChat) => {
  if (!id || id === roomId) return true;
  return resetChat();
};

export const RTCNegotiationServers = [
  { url: 'stun:stun01.sipphone.com' },
  { url: 'stun:stun.ekiga.net' },
  { url: 'stun:stun.fwdnet.net' },
  { url: 'stun:stun.ideasip.com' },
  { url: 'stun:stun.iptel.org' },
  { url: 'stun:stun.rixtelecom.se' },
  { url: 'stun:stun.schlund.de' },
  { url: 'stun:stun.l.google.com:19302' },
  { url: 'stun:stun1.l.google.com:19302' },
  { url: 'stun:stun2.l.google.com:19302' },
  { url: 'stun:stun3.l.google.com:19302' },
  { url: 'stun:stun4.l.google.com:19302' },
  { url: 'stun:stunserver.org' },
  { url: 'stun:stun.softjoys.com' },
  { url: 'stun:stun.voiparound.com' },
  { url: 'stun:stun.voipbuster.com' },
  { url: 'stun:stun.voipstunt.com' },
  { url: 'stun:stun.voxgratia.org' },
  { url: 'stun:stun.xten.com' },
  {
    url: 'turn:numb.viagenie.ca',
    credential: 'muazkh',
    username: 'webrtc@live.com',
  },
  {
    url: 'turn:192.158.29.39:3478?transport=udp',
    credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
    username: '28224511:1379330808',
  },
  {
    url: 'turn:192.158.29.39:3478?transport=tcp',
    credential: 'JZEOEt2V3Qb0y27GRntt2u2PAYA=',
    username: '28224511:1379330808',
  },
];

export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bophuthatswana',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'C.I.S.',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Canary Islands',
  'Cape Verde Islands',
  'Cayman Island',
  'Central African Rep',
  'Chad',
  'Chile',
  'China',
  'Cocos Island',
  'Colombia',
  'Comoros,Republic of',
  'Congo P.R',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Rep of',
  'Denmark',
  'Djibouti Republic of',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt Arab Republic of',
  'El Salvador',
  'Equatorial Guinea.Rep.of',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'French Antilles',
  'French Guiana',
  'French Polynesia',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, Rep (South Korea)',
  'Korea D.P.R(North Korea)',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Midway Island',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Hebrides',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Marianas',
  'Norway',
  'Oman',
  'Pakistan,Islamic Rep',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines Republic of',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion French',
  'Romania',
  'Russia',
  'Rwanda',
  'Samoa',
  'Saotome Principe Dem.Rep',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles Republic',
  'Sierra Leone',
  'Singapore Republic of',
  'Slovakia Republic of',
  'Slovenia',
  'Solomon Islands',
  'Somali,Democrat Republic',
  'South Africa,Republic of',
  'Spain',
  'Sri Lanka',
  'St. Kitts & Nevis',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tadjikistan',
  'Taiwan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tokelau Islands',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Unknown',
  'Uruguay',
  'USA Pacific Islands',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.A.)',
  'Wallis and Futuna Islds',
  'Western Sahara',
  'Windward Islands',
  'Yemen',
  'Yugoslavia',
  'Zaire Republic',
  'Zambia',
  'Zimbabwe',
];
