/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

export const GET_STATISTICS = 'STATISTICS/GET_STATISTICS';
export const GET_GENERAL_STATISTICS = 'STATISTICS/GET_GENERAL_STATISTICS';
export const UPDATE_CHAT_STATISTICS = 'STATISTICS/UPDATE_CHAT_STATISTICS';
export const UPDATE_VIDEOCALL_STATISTICS = 'STATISTICS/UPDATE_VIDEOCALL_STATISTICS';
export const CREATE_VIDEOCALL_STATISTICS = 'STATISTICS/CREATE_VIDEOCALL_STATISTICS';
export const UPDATE_SHARED_STATISTICS = 'STATISTICS/UPDATE_SHARED_STATISTICS';
export const CREATE_SHARED_STATISTICS = 'STATISTICS/CREATE_SHARED_STATISTICS';
export const CREATE_LEVEL_STATISTICS = 'STATISTICS/CREATE_LEVEL_STATISTICS';
export const CREATE_LEADS_STATISTICS = 'STATISTICS/CREATE_LEADS_STATISTICS';
