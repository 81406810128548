/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

// Redux
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import * as globalActions from 'redux/stores/app/actions';
import * as chatActions from 'redux/stores/chat/actions';
import * as messageActions from 'redux/stores/messages/actions';
import * as roomActions from 'redux/stores/room/actions';
import * as statisticsActions from 'redux/stores/statistics/actions';

// Component
import View from './view';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Home page.
 */

/**
 * Map state to props
 * @returns { object }
 */
export const mapStateToProps = state => ({
  loading: state.Global.loading,
  isAuthenticated: state.Auth.isAuthenticated,
  msgData: state.Messages,
  user: state.Auth.user,
  status: state.Auth.status,
  replayMessage: state.Global.replayMessage,
  visitorId: state.Global.visitorId,
  visitorLang: state.Global.visitorLang,
  visitorStatus: state.Global.visitorStatus,
  visitorOnline: state.Global.visitorOnline,
  visitorChating: state.Global.visitorChating,
  visitorEmail: state.Global.visitorEmail,
});

/**
 * Map dispatch to props
 * @param dispatch - Run the  process to execute in the action
 * @returns { object }
 */
export const mapDispatchToProps = dispatch => ({
  globalActions: bindActionCreators(globalActions, dispatch),
  chatActions: bindActionCreators(chatActions, dispatch),
  messageActions: bindActionCreators(messageActions, dispatch),
  roomActions: bindActionCreators(roomActions, dispatch),
  statisticsActions: bindActionCreators(statisticsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(View));
