/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import themeAnimated from '@amcharts/amcharts4/themes/animated';
/**
* @file index.js
* @author Daniela Perea
* @description Card component.
*/

function ChartRates({
  statisticsData,
  selectedStatistic,
  start,
  end,
}) {
  const [data, setData] = useState('');

  const createChart = () => {
    am4core.useTheme(themeAnimated);
    const chart = am4core.create('chartdiv', am4charts.XYChart);

    chart.data = data;
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [{
      label: '...',
      menu: [
        { type: 'pdf', label: 'PDF' },
        { type: 'xlsx', label: 'EXCEL' },
        { label: 'Print', type: 'PRINT' },
      ],
    }];
    chart.exporting.dataFields = {
      Count: 'Avarage Rate',
      Category: 'Counselor',
    };
    chart.exporting.getFormatOptions('pdf').addURL = false;
    chart.exporting.filePrefix = `Rates_${start}_${end}`;
    chart.exporting.adapter.add('pdfmakeDocument', pdf => {
      // Add title to the beginning
      pdf.doc.content.unshift({
        text: `Rates from ${start} to ${end}`,
        margin: [0, 30],
        style: {
          fontSize: 25,
          bold: true,
        },
      });
      return pdf;
    });
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'Category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.labels.template.adapter.add('dy', (dy, target) => {
      if (target.dataItem && target.dataItem.index) {
        return dy + 25;
      }
      return dy;
    });

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 5;
    // Create series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'Rate';
    series.dataFields.categoryX = 'Category';
    series.name = 'Rate';
    series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    series.columns.template.fillOpacity = 0.8;

    const bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = false;
    bullet.dy = 30;
    bullet.label.text = '{valueY}';
    bullet.label.fill = am4core.color('#ffffff');

    // Modify chart's colors

    const columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 0;
    columnTemplate.strokeOpacity = 1;

    series.columns.template.adapter.add('fill', (fill, target) => {
      let amColor = fill;
      if (target.dataItem && (target.dataItem.valueY < 2)) {
        amColor = am4core.color('#E22126');
      } else if (target.dataItem && (target.dataItem.valueY < 3)) {
        amColor = am4core.color('#F47950');
      } else if (target.dataItem && (target.dataItem.valueY < 4)) {
        amColor = am4core.color('#FDAF40');
      } else if (target.dataItem && (target.dataItem.valueY < 5)) {
        amColor = am4core.color('#91CA63');
      } else if (target.dataItem && (target.dataItem.valueY === 5)) {
        amColor = am4core.color('#3BB44C');
      }
      return amColor;
    });
  };

  useEffect(() => {
    const tempData = [];
    statisticsData.forEach(element => {
      const obj = {
        Category: element.name,
        Rate: element.rates,
      };
      tempData.push(obj);
    });
    setData(tempData);
  }, [statisticsData]);

  useEffect(() => {
    if (statisticsData.length > 0) {
      createChart();
    }
  }, [data]);

  useEffect(() => () => {
    am4core.disposeAllCharts();
  }, []);

  return (
    <>
      {statisticsData.length > 0 && (
        <div className="w-full h-full justify-center items-center p-8 overflow-auto min-w-max">
          <h1 className="text-gray-700 font-thin text-2xl mb-8">{selectedStatistic}</h1>
          <div
            id="chartdiv"
            style={{
              height: '80%',
              width: '100%',
            }}
          />
        </div>
      )}
    </>
  );
}

ChartRates.propTypes = {
  statisticsData: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedStatistic: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

export default ChartRates;
