/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

// Constants
import {
  SET_LOADING, SET_RESPONSE, SET_USER, SEND_NOTIFICATION,
  RESET_NOTIFICATION, SET_MENU_OPTION, SET_VISITOR, SET_REPLAY_MESSAGE,
} from './constants';

/**
 * @file actions.js
 * @author Daniel Felipe Lucumi Marin
 * @description Global Application actions.
 */

/**
 * Show/hide loading general to all app
 * @param  { bool } loading - Indcate if show/hide loading
 * @return { object } An action object with a type of SET_LOADING
 */
export const setLoading = loading => ({ type: SET_LOADING, payload: { loading } });

/**
 * Set response state
 * @param  { String } type - Response type
 * @param  { String } title - Response title to display
 * @param  { String } message - Response message to display
 * @param  { String } response - Response data
 * @param  { cb } message - Callback to send response
 * @return { object } An action object with a type of SET_RESPONSE
 */
export const setResponse = (type, title, message, response, cb) => {
  if (cb) {
    cb(type, response);
  }
  return { type: SET_RESPONSE, payload: { type, title, message } };
};

/**
 * Set response state
 * @param  { object } user - User data
 * @return { object } An action object with a type of SET_USER
 */
export const setUser = data => ({ type: SET_USER, payload: data });

export const sendNotification = data => ({
  type: SEND_NOTIFICATION,
  payload: data,
});

export const resetNotification = data => ({
  type: RESET_NOTIFICATION,
  payload: data,
});

export const setMenuOption = payload => ({
  type: SET_MENU_OPTION,
  payload,
});

export const setVisitorSelected = (id, lang, status, online, isChating, email) => ({
  type: SET_VISITOR,
  payload: {
    id,
    lang,
    status,
    online,
    isChating,
    email,
  },
});

export const setReplayMessage = message => ({
  type: SET_REPLAY_MESSAGE,
  payload: message,
});
