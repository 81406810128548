/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

/**
 * @file index.js
 * @author Daniela Perea
 * @description Card component.
 */

function StatisticsTable({
  statisticsData,
  selectedStatistic,
  start,
  end,
}) {
  useEffect(() => {
  }, [statisticsData]);

  return (
    <>
      {statisticsData.length > 0 && (
        <div className="w-full h-full justify-center items-center p-8 overflow-auto min-w-max">
          <div className="flex flex-row justify-between">
            <h1 className="text-gray-700 font-thin text-2xl mb-8">{selectedStatistic}</h1>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="bg-blue-500 hover:bg-blue-700 text-white rounded-md py-1 px-3 h-8 focus:outline-none"
              table="mytable"
              filename={`General_statistics_${start}_${end}`}
              sheet="tablexls"
              buttonText="Download as XLS"
            />
          </div>
          <div className="rounded-r-sm">
            <table id="mytable" className="table-auto font-thin border overflow-x-auto w-full">
              <thead>
                <tr className="bg-white text-gray-700  text-sm font-thin">
                  <th className="py-3 text-center border">Name</th>
                  <th className="py-3 text-center border">Rates</th>
                  <th className="py-3 text-center border">Counseling Time</th>
                  <th className="py-3 text-center border">Chats</th>
                  <th className="py-3 text-center border">Unique Chats</th>
                  <th className="py-3 text-center border">Videocalls</th>
                  <th className="py-3 text-center border">High Potential</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-thin">
                {statisticsData
                  && statisticsData.map(item => (
                    <tr
                      className="border-b border-gray-200 hover:bg-gray-100"
                      key={item.name}
                    >
                      <td className="py-3 border" align="center">
                        {item.name}
                      </td>
                      <td className="py-3 border" align="center">
                        {item.rates}
                      </td>
                      <td className="py-3 border" align="center">
                        {item.counseling_time}
                      </td>
                      <td className="py-3 border" align="center">
                        {item.chats}
                      </td>
                      <td className="py-3 border" align="center">
                        {item.unique_chats}
                      </td>
                      <td className="py-3 border" align="center">
                        {item.videocalls}
                      </td>
                      <td className="py-3 border" align="center">
                        {item.high_potential}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

StatisticsTable.propTypes = {
  statisticsData: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedStatistic: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

export default StatisticsTable;
