/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * @file index.js
 * @author Juan David Rojas
 * @description Modal component
 */

const Modal = ({
  title, isOpen, children, action, size,
}) => (
  <>
    {isOpen && (
      <>
        <div className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className={`relative ${size} mx-auto mt-24`}>
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="font-semibold text-gray-800">{title}</h3>
                <button
                  type="button"
                  className="p-1 ml-auto bg-transparent border-0 text-red-600 float-right text-2xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => action(false)}
                >
                  x
                </button>
              </div>
              <div className="relative p-6 flex-auto">{children}</div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>
    )}
  </>
);

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  action: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  size: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  isOpen: false,
};

export default Modal;
