/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

// Redux
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import * as globalActions from 'redux/stores/app/actions';
import * as statisticActions from 'redux/stores/statistics/actions';

// Component
import View from './view';

/**
 * @file index.js
 * @author Daniela Perea
 * @description Home page.
 */

/**
 * Map state to props
 * @returns { object }
 */
export const mapStateToProps = state => ({
  loading: state.Global.loading,
  isAuthenticated: state.Auth.isAuthenticated,
  user: state.Auth.user,
});

/**
 * Map dispatch to props
 * @param dispatch - Run the  process to execute in the action
 * @returns { object }
 */
export const mapDispatchToProps = dispatch => ({
  globalAction: bindActionCreators(globalActions, dispatch),
  statisticActions: bindActionCreators(statisticActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(View));
