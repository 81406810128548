/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useState, useEffect } from 'react';
import S3 from 'react-aws-s3';
import { BiMicrophone, BiMicrophoneOff } from 'react-icons/bi';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import PropTypes from 'prop-types';

// Utils
import { AWS_CONFIG } from 'utils/enviroment';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Audio recoder component
 */

const AudioComponent = ({
  recording, setAudio, setIsRecording,
}) => {
  const [recordState, setRecordState] = useState(null);

  useEffect(() => {
    setRecordState(null);
  }, [setAudio]);

  const start = () => {
    setRecordState(RecordState.START);
    setIsRecording(true);
  };

  const stop = () => {
    setRecordState(RecordState.STOP);
    setIsRecording(false);
  };

  const handleUploadAudio = async audioData => {
    const date = new Date();
    const file = new File([audioData.blob], date.valueOf(), {
      type: 'audio/wav',
    });
    const newFileName = file.name;
    const config = AWS_CONFIG;
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName)
      .then(data => {
        if (data.status === 204) {
          setAudio({ ...audioData, url: data.location });
        }
      });
  };

  const onStop = audioData => {
    handleUploadAudio(audioData);
  };

  return (
    <div className="mx-5 flex flex-row">
      <AudioReactRecorder
        state={recordState}
        onStop={onStop}
        canvasWidth={`${recording ? '50' : '0'}`}
        canvasHeight={`${recording ? '50' : '0'}`}
      />
      <button
        className="flex items-center justify-center border-none focus:outline-none bg-green-500 rounded-full w-12 h-12"
        onClick={() => (!recording ? start() : stop())}
        type="button"
        tabIndex={0}
        title="Record voice note"
      >
        {recording ? (
          <BiMicrophoneOff
            className="text-white w-5 h-5"
          />
        ) : (
          <BiMicrophone
            className="text-white w-5 h-5"
          />
        )}
      </button>
    </div>
  );
};

AudioComponent.propTypes = {
  recording: PropTypes.bool.isRequired,
  setIsRecording: PropTypes.func.isRequired,
  setAudio: PropTypes.func.isRequired,
};

export default AudioComponent;
