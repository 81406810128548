/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import moment from 'moment';

/**
 * @author Daniel Felipe Lucumi Marin
 * @file request.js
 * @description Execute request
 */

function handleMessage(type = 'Text', message, room, user) {
  return {
    type,
    room_id: room,
    content: message,
    user_id: user,
    check: false,
    created_at: moment().format('YYYY-MM-DD hh:mm:ss'),
    updated_at: moment().format('YYYY-MM-DD hh:mm:ss'),
  };
}

export default handleMessage;
