/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Utils
import { generateMessagesPDF } from 'utils/helpers';

// Assets
import { SiGooglecalendar, SiMinutemailer } from 'react-icons/si';
import { RiQuestionnaireFill } from 'react-icons/ri';
import { FiLink } from 'react-icons/fi';

export default function TopChat({ handleSendOption }) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center space-x-2">
      <span className="flex items-center rounded-full justify-center hover:bg-gray-100 p-3 text-gray-300 hover:text-gray-600">
        <SiGooglecalendar
          className="cursor-pointer w-5 h-5 "
          onClick={() => handleSendOption('Calendly', t('alert-message-1'))}
          title="send calendar"
        />
      </span>
      <span className="flex items-center rounded-full hover:bg-gray-100 p-3 text-gray-300 hover:text-gray-600">
        <RiQuestionnaireFill
          className="cursor-pointer  w-5 h-5"
          onClick={() => handleSendOption('Survey', t('alert-message-2'))}
          title="send survey"
        />
      </span>
      <span className="flex items-center rounded-full hover:bg-gray-100 p-3 text-gray-300 hover:text-gray-600">
        <SiMinutemailer
          className="cursor-pointer w-5 h-5"
          onClick={() => handleSendOption('AdviserCopy', t('alert-message-3'), generateMessagesPDF)}
          title="send chat history"
        />
      </span>
      <span className="flex items-center rounded-full hover:bg-gray-100 p-3 text-gray-300 hover:text-gray-600">
        <FiLink
          className="cursor-pointer w-5 h-5"
          onClick={() => handleSendOption('urlCM', t('alert-message-7'))}
          title="send CM url"
        />
      </span>
    </div>
  );
}

TopChat.propTypes = {
  handleSendOption: PropTypes.func.isRequired,
};
