/* eslint-disable no-unused-vars */
/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import { put, call, takeLatest } from 'redux-saga/effects';

// Redux
import { setResponse } from 'redux/stores/app/actions';
import { renderMessages } from 'redux/stores/messages/actions';

// Utils
import request from 'utils/request';

// Utils
import { TRANSLATE_API_KEY } from 'utils/enviroment';

// Constants
import {
  SEND_MESSAGE,
  GET_MESSAGES,
  CHECK_VISITOR_MESSAGES,
} from './constants';

/**
 * @author Daniel Felipe Lucumi Marin
 * @file request.js
 * @description Execute request
 */

function* sendMessage(action) {
  const {
    cb,
    payload: { data, lang: visitorLang },
  } = action;
  const url = '/message/create';
  const urlTranslate = `https://translation.googleapis.com/language/translate/v2?key=${TRANSLATE_API_KEY}`;
  const requestOptions = { method: 'POST', body: JSON.stringify(data) };

  try {
    const myLang = navigator.language.split('-')[0];
    if (data.type !== 'Text' || myLang === visitorLang) {
      const response = yield call(request, url, requestOptions);
      yield put(setResponse('success', false, false, response, cb));
    } else {
      const translationData = {
        q: data.content,
        source: myLang,
        target: visitorLang,
        format: 'text',

      };
      const requestTranslationOptions = { method: 'POST', externalUrl: true, body: JSON.stringify(translationData) };

      const responseTranslation = yield call(request, urlTranslate, requestTranslationOptions);
      const { data: { translations } } = responseTranslation;
      const messageTranslation = translations[0].translatedText;

      data.lang = visitorLang;
      data.translation = messageTranslation;

      const requestMessageTranslatedOptions = { method: 'POST', body: JSON.stringify(data) };

      const response = yield call(request, url, requestMessageTranslatedOptions);
      yield put(setResponse('success', false, false, response, cb));
    }
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* getMessages(action) {
  const {
    cb,
    payload: { page, room },
  } = action;

  const limit = 10;
  const offset = 0 + (page - 1) * limit;
  const url = `/message/paginate?room=${room}&limit=${limit}&offset=${offset}`;
  const requestOptions = {
    method: 'GET',
  };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(renderMessages(response));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* checkMessages(action) {
  const {
    payload: { visitorId, roomId },
    cb,
  } = action;

  const url = '/message/check';
  const requestOptions = { method: 'PUT', body: JSON.stringify({ visitor: visitorId, room: roomId }) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

/**
 * Declare all component sagas
 */

export default function* rootSaga() {
  yield takeLatest(SEND_MESSAGE, sendMessage);
  yield takeLatest(GET_MESSAGES, getMessages);
  yield takeLatest(CHECK_VISITOR_MESSAGES, checkMessages);
}
