/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import { put, call, takeLatest } from 'redux-saga/effects';

// Redux
import { setResponse } from 'redux/stores/app/actions';

// Utils
import request from 'utils/request';

// Constants
import {
  GET_VISITORS_INFO,
} from './constants';

/**
 * @file saga.js
 * @author Daniela Perea
 * @description profile saga.
 */

function* getVisitorsInfo(action) {
  const {
    cb,
    payload: {
      id, country, nationality, endDate, startDate,
    },
  } = action;
  const url = `/user/visitors/${id}?country=${country}&nationality=${nationality}&startDate=${startDate}&endDate=${endDate}`;
  const requestOptions = {
    method: 'GET',
  };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

/**
 * Declare all component sagas
 */

export default function* rootSaga() {
  yield takeLatest(GET_VISITORS_INFO, getVisitorsInfo);
}
