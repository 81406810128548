/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Login page.
 */

const getInitialTheme = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = window.localStorage.getItem('color-theme');
    if (typeof storedPrefs === 'string') {
      return storedPrefs;
    }

    const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
    if (userMedia.matches) {
      return 'dark';
    }
  }

  return 'dark';
};

export const ThemeContext = React.createContext();

const ThemeProv = ({ initialTheme, children }) => {
  const [theme, setTheme] = React.useState(getInitialTheme);

  const rawSetTheme = themes => {
    const root = window.document.documentElement;
    const isDark = themes === 'dark';

    root.classList.remove(isDark ? 'light' : 'dark');
    root.classList.add(themes);

    localStorage.setItem('color-theme', themes);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  React.useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

/**
 * Proptypes definition.
 */
ThemeProv.propTypes = {
  initialTheme: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export const ThemeProvider = ThemeProv;
