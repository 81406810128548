/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University
 * All Rights Reserved.
 */

import { combineReducers } from 'redux';
import persistStore from './persistStore';
import Auth from './stores/auth/reducer';
import Global from './stores/app/reducer';
import Rooms from './stores/room/reducer';
import Messages from './stores/messages/reducer';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Redux store.
 */

const RootReducer = combineReducers({
  Auth,
  Global,
  persistStore,
  Rooms,
  Messages,
});

export default RootReducer;
