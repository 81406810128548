/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

// Utils
import { renderError } from 'utils/helpers';

// Icons
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';

// Scheme
import schema from './formConfig';

/**
 * @file index.jsx
 * @author Daniela perea
 * @description Login form component.
 */

function ChangePasswordForm({ submitForm, newPasswordActions, oldPasswordActions }) {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const { hideOldPass, setHideOldPass } = oldPasswordActions;
  const { hideNewPass, setHideNewPass } = newPasswordActions;
  const { t } = useTranslation();

  const onSubmit = data => submitForm(data);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-min"
    >
      <h1 className="font-ligth text-blue-500 border-b w-max border-blue-500 pr-2">
        {t('profile-title')}
      </h1>
      <hr />
      <div className="w-full h-full pt-10 px-10">
        <div className="flex flex-col mb-4">
          <div className="flex flex-row relative">
            <h1 className="font-thin mb-5 w-1/4 text-gray-600">
              {t('form-input-8')}
            </h1>
            <button
              type="button"
              className="absolute bottom-5 right-2 bg-transparent px-2 border-0 rounded rounded-r-none text-lg text-gray-600 focus:outline-none"
              onClick={() => setHideOldPass(!hideOldPass)}
            >
              {hideOldPass ? (
                <FaRegEyeSlash />
              ) : (
                <FaRegEye />
              )}
            </button>
            <div className="w-full flex-col">
              <input
                className="w-full focus:outline-none focus:border-blue-300 px-2 border-gray-300 border focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-50 rounded-sm h-8 text-sm font-thin"
                type={hideOldPass ? 'password' : 'text'}
                name="oldPassword"
                ref={register}
              />
              {errors.oldPassword && renderError(errors.oldPassword.message)}
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <div className="flex flex-row relative">
            <h1 className="font-thin w-1/4 text-gray-600">
              {t('form-input-9')}
            </h1>
            <button
              type="button"
              className="absolute bottom-2 right-2 bg-transparent px-2 border-0 rounded rounded-r-none text-lg text-gray-600 focus:outline-none"
              onClick={() => setHideNewPass(!hideNewPass)}
            >
              {hideNewPass ? (
                <FaRegEyeSlash />
              ) : (
                <FaRegEye />
              )}
            </button>
            <div className="w-full flex-col">
              <input
                className="w-full focus:outline-none focus:border-blue-300 px-2 border-gray-300 border focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-50 rounded-sm h-8 text-sm font-thin"
                type={hideNewPass ? 'password' : 'text'}
                name="newPassword"
                ref={register}
              />
              {errors.newPassword && renderError(errors.newPassword.message)}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end pt-3">
          <button type="submit" className="w-24 h-8 text-sm font-thin bg-blue-400 hover:bg-blue-500 text-white rounded-sm focus:outline-none focus:border-blue-300">
            {t('from-btn-1')}
          </button>
        </div>
      </div>
    </form>
  );
}

/**
 * Proptypes definition.
 */
ChangePasswordForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  newPasswordActions: PropTypes.objectOf(PropTypes.any).isRequired,
  oldPasswordActions: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ChangePasswordForm;
