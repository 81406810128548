/* eslint-disable react/no-array-index-key */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useState, useEffect } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Containers
import AppLayout from 'containers/layout';
import ChatBox from 'containers/chat';

// Components
import Card from 'components/card';

// Assets
import { FiFilter } from 'react-icons/fi';
import Modal from 'components/modal';

// Utils
import { countries } from 'utils/helpers';
import moment from 'moment';

/**
 * @file view.js
 * @author Daniela Perea
 * @description History visitors Page.
 */

function Visitors({ getVisitorsInfo, user, messageActions: { resetChat } }) {
  const [userData, setUserData] = useState([]);
  const [modalFilter, setModalFilter] = useState(false);
  const [startDate, setSelectedStartDate] = useState(new Date());
  const [endDate, setSelectedEndDate] = useState(new Date());
  const [country, setSelectedCountry] = useState('All');
  const [nationality, setSelectedNationality] = useState('All');

  const useStyles = makeStyles(() => ({
    button: {
      outline: 'none',
      fontSize: '0.5em',
      margin: '0px 15px',
      color: '#E5E7EB',
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const handleThisWeek = () => {
    setSelectedStartDate(moment().startOf('isoweek').toDate());
    setSelectedEndDate(moment().endOf('isoweek').toDate());
  };

  const getVisitors = () => {
    getVisitorsInfo(
      {
        id: user.id,
        country,
        nationality,
        startDate: moment(startDate).format('YYYY-MM-DD HH:mm'),
        endDate: moment(endDate).format('YYYY-MM-DD HH:mm'),
      },
      (type, response) => {
        if (type === 'success') {
          setUserData(response);
        }
      },
    );
  };

  const renderRoom = () => {
    const { location } = history;
    if (location.pathname === '/visitors') {
      return null;
    }

    return <ChatBox isDisabled />;
  };

  useEffect(() => {
    handleThisWeek();
    getVisitors();
  }, []);

  useEffect(() => {
    getVisitors();
  }, [startDate, endDate, country, nationality]);

  return (
    <AppLayout title={t('visitor-title')}>
      <div className="border m-8 w-full rounded-sm flex-row flex">
        <section className="flex flex-col w-full max-w-sm flex-none bg-white min-h-0 h-full  rounded-l-sm border-r">
          <div className="h-16 border-b items-center flex justify-between">
            <h1 className="text-gray-700 font-thin m-4">
              {t('visitor-subtitle')}
            </h1>
            <FiFilter
              size={25}
              className="pr-2"
              color="#34A3D4"
              onClick={() => setModalFilter(!modalFilter)}
            />
          </div>
          <div className="w-full flex-1 p-4 overflow-auto">
            {userData
              && userData.map((item, index) => (
                <Card key={index} isVisitor item={item} resetChat={resetChat} />
              ))}
          </div>
        </section>
        <section className="flex flex-col w-full h-full ">
          {renderRoom()}
        </section>
      </div>
      {modalFilter && (
        <Modal
          title="History filters"
          isOpen={modalFilter}
          action={setModalFilter}
          size="w-2/6"
        >
          <label htmlFor="nationality">Nationality</label>
          <select
            id="nationality"
            value={nationality}
            defaultValue="All"
            className="border-2 mt-2 bg-transparent w-full rounded-lg outline-none border-blue-300 py-2 mb-2"
            onChange={e => setSelectedNationality(e.target.value)}
          >
            {countries.map(item => (
              <option value={item}>{item}</option>
            ))}
          </select>
          <label htmlFor="country">Country</label>
          <select
            className="border-2 mt-2 bg-transparent w-full rounded-lg outline-none border-blue-300 py-2 mb-2"
            value={country}
            defaultValue="All"
            onChange={e => setSelectedCountry(e.target.value)}
          >
            {countries.map(item => (
              <option value={item}>{item}</option>
            ))}
          </select>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            className="flex flex-row w-full focus:outline-none"
          >
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="YYYY-MM-DD"
              margin="normal"
              id="date-pickere"
              label="Start date"
              className={classes.button}
              focused={false}
              value={startDate}
              onChange={date => setSelectedStartDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM-DD-YYYY"
              margin="normal"
              id="date-picker-inline"
              label="End date"
              className={classes.button}
              value={endDate}
              onChange={date => setSelectedEndDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white rounded-md py-1 px-3 mt-4"
            onClick={() => handleThisWeek()}
          >
            This week
          </button>
        </Modal>
      )}
    </AppLayout>
  );
}

/**
 * Proptypes definition.
 */
Visitors.propTypes = {
  getVisitorsInfo: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  messageActions: PropTypes.shape({
    resetChat: PropTypes.func,
  }).isRequired,
};

export default Visitors;
