/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';

// Icons
import { FiUser } from 'react-icons/fi';

// Utils
import { handleResetChat } from 'utils/helpers';

/**
 * @file index.js
 * @author Brayan David Coy
 * @description Card component.
 */

function Card({
  isVisitor,
  item,
  setSelectedStatistic,
  selectedStatistic,
  resetChat,
  itemStatistics,
}) {
  const { id } = useParams();

  return (
    <>
      {isVisitor ? (
        <div
          className="w-full justify-start flex flex-row text-gray-500 items-center font-thin my-1 focus:outline-none hover:text-blue-500"
          activeClassName="text-blue-500"
        >
          <div className="border-r border-t border-b w-full rounded-md mb-4 hover:shadow-sm">
            <div className="border-l-4 border-blue-500 rounded-md p-5 w-full h-full flex-row">
              <FiUser className="h-4 w-4 mr-3" />
              <div className="flex-col flex">
                <NavLink
                  key={item.id}
                  to={`/visitors/chat/${item.room.id}`}
                  onClick={() => handleResetChat(id, item.room.id, resetChat)}
                >
                  <h1 className=" font-thin text-left">{`${item.first_name} ${item.last_name}`}</h1>
                </NavLink>
                <h1 className="text-gray-700 font-thin mb-4">{item.email}</h1>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setSelectedStatistic(itemStatistics)}
          type="button"
          className="w-full justify-start flex flex-row text-gray-700 font-thin focus:outline-none hover:text-blue-500"
        >
          <div className="border-r border-t border-b w-full rounded-md mb-4 hover:shadow-sm">
            <div className="border-l-4 border-blue-500 rounded-md p-5 w-full h-full">
              <h1 className={`font-thin text-left ${selectedStatistic === itemStatistics ? 'text-blue-500' : 'text-gray-700'}`}>{itemStatistics}</h1>
            </div>
          </div>
        </button>
      )}
    </>
  );
}

Card.propTypes = {
  isVisitor: PropTypes.bool,
  item: PropTypes.shape(PropTypes.any),
  setSelectedStatistic: PropTypes.func,
  selectedStatistic: PropTypes.string,
  itemStatistics: PropTypes.string,
  resetChat: PropTypes.func.isRequired,
};

Card.defaultProps = {
  isVisitor: false,
  setSelectedStatistic: null,
  selectedStatistic: '',
  itemStatistics: '',
  item: {},
};

export default Card;
