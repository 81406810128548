/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import { put, call, takeLatest } from 'redux-saga/effects';

// Redux
import { setResponse } from 'redux/stores/app/actions';

// Utils
import request from 'utils/request';

// Constants
import {
  GET_GENERAL_STATISTICS,
  GET_STATISTICS,
  UPDATE_CHAT_STATISTICS,
  UPDATE_VIDEOCALL_STATISTICS,
  CREATE_VIDEOCALL_STATISTICS,
  CREATE_SHARED_STATISTICS,
  UPDATE_SHARED_STATISTICS,
  CREATE_LEVEL_STATISTICS,
  CREATE_LEADS_STATISTICS,
} from './constants';

/**
 * @file saga.js
 * @author Daniela Perea
 * @description profile saga.
 */

function* getStatistics(action) {
  const {
    cb,
    payload: { start, end },
  } = action;
  const url = `/statistics/getStatistics/${start}/${end}`;
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* getGeneralStatistics(action) {
  const {
    cb,
    payload: { start, end },
  } = action;

  const url = `/statistics/getGeneralStatistics/${start}/${end}`;
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* updateChatStatistic(action) {
  const {
    cb,
    payload,
  } = action;
  const url = '/statistics/update-chat-statistics';
  const requestOptions = { method: 'PUT', body: JSON.stringify({ id: payload }) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* updateVideocallStatistic(action) {
  const {
    cb,
    payload,
  } = action;
  const url = '/statistics/update-videocall-statistics';
  const requestOptions = { method: 'PUT', body: JSON.stringify({ id: payload }) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* createVideocallStatistic(action) {
  const {
    cb,
    payload,
  } = action;
  const url = '/statistics/create-videocall-statistics';
  const requestOptions = { method: 'POST', body: JSON.stringify({ id: payload }) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* updateSharedStatistic(action) {
  const {
    cb,
    payload: { id, type },
  } = action;
  const url = '/statistics/update-screen-statistics';
  const requestOptions = { method: 'PUT', body: JSON.stringify({ id, type }) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* createSharedStatistic(action) {
  const {
    cb,
    payload,
  } = action;
  const url = '/statistics/create-screen-statistics';
  const requestOptions = { method: 'POST', body: JSON.stringify({ id: payload }) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* createLevelStatistic(action) {
  const {
    cb,
    payload: { id, type },
  } = action;
  const url = '/statistics/visitor-level-statistics';
  const requestOptions = { method: 'POST', body: JSON.stringify({ id, type }) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* createLeadsStatistic(action) {
  const {
    cb,
    payload: { start, end },
  } = action;

  const url = `/statistics/all-leads-statistics/${start}/${end}`;
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

/**
 * Declare all component sagas
 */

export default function* rootSaga() {
  yield takeLatest(GET_GENERAL_STATISTICS, getGeneralStatistics);
  yield takeLatest(GET_STATISTICS, getStatistics);
  yield takeLatest(UPDATE_CHAT_STATISTICS, updateChatStatistic);
  yield takeLatest(UPDATE_VIDEOCALL_STATISTICS, updateVideocallStatistic);
  yield takeLatest(CREATE_VIDEOCALL_STATISTICS, createVideocallStatistic);
  yield takeLatest(UPDATE_SHARED_STATISTICS, updateSharedStatistic);
  yield takeLatest(CREATE_SHARED_STATISTICS, createSharedStatistic);
  yield takeLatest(CREATE_LEVEL_STATISTICS, createLevelStatistic);
  yield takeLatest(CREATE_LEADS_STATISTICS, createLeadsStatistic);
}
