/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import routes from 'routes/routes';

// Contexts
import { ThemeProvider } from 'containers/context/themeContext';
import { SocketContext, socket } from 'containers/context/socket';

// Components
import NotFound from 'pages/noFound';
import PublicRoute from './public';
import PrivateRoute from './private';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Route index.
 */

const Routes = () => (
  <ThemeProvider initialTheme="light">
    <SocketContext.Provider value={socket}>
      <Router>
        <Switch>
          {routes.map(route => {
            if (route.auth) {
              return <PrivateRoute key={route.path} {...route} />;
            }
            return <PublicRoute key={route.path} {...route} />;
          })}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </SocketContext.Provider>
  </ThemeProvider>
);

export default Routes;
