/* eslint-disable no-unused-vars */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FiUser, FiLogOut } from 'react-icons/fi';

// Context
import { SocketContext } from 'containers/context/socket';

export default function PopoverTopbar({ logoutFn, user }) {
  const [visibility, setVisibility] = useState(false);
  const { t } = useTranslation();
  const socket = useContext(SocketContext);

  return (
    <div>
      <div
        className="relative inline-block text-left"
      >
        <div>
          <button
            type="button"
            onClick={() => setVisibility(!visibility)}
            className=" mx-auto flex items-center rounded-full bg-gray-100 p-3 text-gray-600 hover:bg-gray-300 hover:text-gray-700 focus:outline-none"
            id="options-menu"
          >
            <FiUser className="w-4 h-4 text-gray-600" />
          </button>
        </div>
        {visibility && (
          <div
            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          >
            <div
              className="py-1 "
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <Link to="/profile" className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full">
                <span className="flex flex-row justify-start items-center font-thin text-gray-400 hover:text-gray-600">
                  <FiUser className="w-4 h-4 mr-4 " />
                  {t('topbar-op-1')}
                </span>
              </Link>
              <button
                type="button"
                className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
                role="menuitem"
                onClick={() => {
                  logoutFn({ id: user.id }, (type, response) => {
                    if (type === 'success') {
                      socket.emit('onUserDisconnect', user.id);
                    }
                  });
                  setVisibility(!visibility);
                }}
              >
                <span className="flex flex-row justify-start items-center font-thin text-gray-400 hover:text-gray-600">
                  <FiLogOut className="w-4 h-4 mr-4" />
                  {t('topbar-op-2')}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

PopoverTopbar.propTypes = {
  logoutFn: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};
