/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Containers
import AppLayout from 'containers/layout';
import ActiveChat from 'containers/activeChat';
import Visitors from 'containers/visitors';
import ChatBox from 'containers/chat';

// Helpers
import { getQRcode, getNotificationsPermissions } from 'utils/helpers';

/**
 * @file view.js
 * @author Daniel Felipe Lucumi Marin
 * @description Home page.
 */

function home({ chatActions: { getWpQr }, user }) {
  const [qr, setQr] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    getWpQr(async (type, response) => {
      if (type === 'success') {
        setQr(await getQRcode(response));
      }
    });
    getNotificationsPermissions();
  }, []);

  const renderView = () => {
    const { location } = history;
    if (location.pathname === '/home') {
      return <Visitors />;
    }

    return <ChatBox />;
  };

  return (
    <AppLayout title={t('main-title')}>
      <div className="border m-8 w-full rounded-sm flex-row flex">
        {user.type !== 'Admin' && (
          <section className="flex flex-col w-full max-w-sm flex-none bg-white min-h-0 h-full  rounded-l-sm border-r">
            <div className="items-center flex p-2 border-b h-16 px-5">
              <h1 className="text-gray-700 font-thin mx-4">
                {t('main-subtitle')}
              </h1>
            </div>
            <div className=" w-full text-center hidden">
              {qr && (qr)}
            </div>
            <div className="flex-1 w-full p-4 overflow-auto">
              <ActiveChat />
            </div>
          </section>
        )}
        <section className="flex flex-col h-full w-full">
          {renderView()}
        </section>
      </div>
    </AppLayout>
  );
}

export default home;
