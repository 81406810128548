/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import {
  AUTH_CHECK,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  USER_OFFLINE,
}
  from './constants';

/**
 * @file actions.js
 * @author Brayan David Coy
 * @description auth actions.
 */

export function authLogin(payload) {
  return {
    type: AUTH_LOGIN,
    payload,
  };
}

export function authLogout(payload, cb) {
  return {
    type: AUTH_LOGOUT,
    payload,
    cb,
  };
}

export function authCheck() {
  return {
    type: AUTH_CHECK,
  };
}

export function setUserStatus(id, online, cb) {
  return {
    type: USER_OFFLINE,
    payload: { id, online },
    cb,
  };
}
