/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

// Utils
import { renderError } from 'utils/helpers';

// Scheme
import schema from './formConfig';

/**
 * @file index.jsx
 * @author Daniel Felipe Lucumi Marin
 * @description Login form component.
 */

function LoginForm({ submitForm, lang }) {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = data => submitForm(data);

  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex">
        <div className="w-1/2 p-2">
          <span className="block">{t('form-input-7')}</span>
          <select
            className="form-select w-full"
            name="language_id"
            ref={register}
          >
            <option value="">{t('form-input-13')}</option>
            {
              lang.map(item => (<option key={`lang-${item.id}`} value={item.id}>{item.name}</option>))
            }
          </select>
          {errors.language_id && renderError(errors.language_id.message)}
        </div>
        <div className="w-1/2 p-2">
          <span className="block">{t('form-input-10')}</span>
          <input type="text" name="code" id="code" className="form-input w-full" ref={register} />
          {errors.code && renderError(errors.code.message)}
        </div>
      </div>
      <div className="flex w-full p-2 flex-col">
        <span className="block">{t('form-input-11')}</span>
        <textarea className="form-textarea w-full" name="content" ref={register} />
        {errors.content && renderError(errors.content.message)}
      </div>
      <div className="text-center mt-3">
        <button
          className="text-white leading-normal flex-1 border-0 h-10 rounded rounded-l-none px-3 self-center relative text-xl outline-none w-1/2 bg-teal-900"
          type="submit"
        >
          <span>{t('from-btn-1')}</span>
        </button>
      </div>
    </form>
  );
}

/**
 * Proptypes definition.
 */
LoginForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  lang: PropTypes.arrayOf.isRequired,
};

export default LoginForm;
