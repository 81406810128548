/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

/**
 * @author Daniel Felipe Lucumi Marin
 * @file request.js
 * @description Execute request
 */

import { tryParseJSON } from 'utils/helpers';

/**
 *
 * @param {*} string key
 * @returns string
 */
export const getItem = key => {
  const response = window.localStorage.getItem(key);
  return tryParseJSON(response) || response;
};

/**
 *
 * @param {*} string key
 * @param {*} object value
 * @returns object
 */
export const setItem = (key, value) => JSON.stringify(window.localStorage.setItem(key, value));
