/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

export const GET_MY_ROOMS = 'ROOM/GET_MY_ROOMS';
export const SET_ONLINE_ROOMS = 'ROOM/SET_ONLINE_ROOMS';
export const CLOSE_ROOM = 'ROOM/CLOSE_ROOM';
export const HIDE_ROOM = 'ROOM/HIDE_ROOM';
export const SELECTED_ROOM = 'ROOM/SELECTED_ROOM';
