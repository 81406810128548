/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

// eslint-disable-next-line import/prefer-default-export
export const GET_VISITORS = 'CHAT/GET_VISITORS';
export const GET_SHORTCODES = 'CHAT/GET_SHORTCODES';
export const GET_LANGUAGES = 'CHAT/GET_LANGUAGES';
export const SAVE_SHORTCODE = 'CHAT/SAVE_SHORTCODE';
export const GET_WP_STATUS = 'CHAT/GET_WP_STATUS';
export const GET_WP_QR = 'CHAT/GET_WP_QR';
export const SEND_ADVISER_COPY = 'CHAT/SEND_ADVISER_COPY';
export const GET_ALL_SHORTCODES = 'CHAT/GET_ALL_SHORTCODES';
export const DELETE_SHORTCODE = 'CHAT/DELETE_SHORTCODE';
export const GET_CM_URL = 'CHAT/GET_CM_URL';
