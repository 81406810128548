/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

export const runVisitorEvent = (type, payload, data) => {
  try {
    if (type === 'visitor') {
      const validation = data.find(item => payload.id === item.id);
      if (!validation) {
        return [...data, payload];
      }
    } else {
      const index = data.findIndex(item => payload.id === item.id);
      if (index !== -1) data.splice(index, 1);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }

  return [...data];
};

const runRoomsEvent = (type, payload, data) => {
  try {
    if (type === 'add_room') {
      const validation = data.find(item => payload.id === item.id);
      if (!validation) {
        return [...data, ...payload];
      }
    } else {
      const index = data.findIndex(item => payload.id === item.id);
      if (index !== -1) data.splice(index, 1);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }

  return [...data];
};

const runAddMessageEvent = (type, payload, data) => {
  try {
    if (type === 'add_message') {
      return [...data, payload];
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }

  return [...data];
};

export const fireUpdates = (type, payload, data) => {
  switch (type) {
    case 'visitor':
    case 'leave_visitor':
      return runVisitorEvent(type, payload, data);
    case 'add_room':
    case 'remove_room':
      return runRoomsEvent(type, payload, data);
    case 'add_message':
      return runAddMessageEvent(type, payload, data);
    default:
      return data;
  }
};
