/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import * as yup from 'yup';

/**
* @file index.jsx
* @author Daniela Perea.
* @description form config validation.
*/

const schema = yup.object().shape({
  email: yup
    .string()
    .email('La dirección de correo electronico NO es valida')
    .required('Este campo es obligatorio'),
  last_name: yup
    .string('El apellido NO es valido')
    .required('Este campo es obligatorio'),
  first_name: yup
    .string('El nombre NO es valido')
    .required('Este campo es obligatorio'),
  phone: yup
    .number()
    .typeError('El teléfono NO es valido')
    .required('Este campo es obligatorio'),
  country_name: yup
    .string('El pais NO es valido')
    .required('Este campo es obligatorio'),
  country_code: yup
    .number()
    .typeError('El codigo NO es valido')
    .required('Este campo es obligatorio'),
});

export default schema;
