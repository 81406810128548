/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import {
  SEND_MESSAGE,
  GET_MESSAGES,
  RENDER_MESSAGES,
  ADD_MESSAGE,
  CHECK_VISITOR_MESSAGES,
  RESET_CHAT,
} from './constants';

/**
 * @file actions.js
 * @author Daniel Felipe Lucumi Marin
 * @description destinations actions.
 */

export const sendMessage = (data, lang, cb) => ({
  type: SEND_MESSAGE,
  payload: { data, lang },
  cb,
});

export const getMessages = (page, room) => ({
  type: GET_MESSAGES,
  payload: { page, room },
});

export const renderMessages = messages => ({
  type: RENDER_MESSAGES,
  payload: messages,
});

export const addMessages = message => ({
  type: ADD_MESSAGE,
  payload: message,
});

export const checkVisitorMessages = (visitorId, roomId, cb) => ({
  type: CHECK_VISITOR_MESSAGES,
  payload: { visitorId, roomId },
  cb,
});

export const resetChat = () => ({
  type: RESET_CHAT,
});
