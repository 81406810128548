/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

// Dependencies
import { all, fork } from 'redux-saga/effects';

// Sagas
import authSaga from './stores/auth/saga';
import chatSaga from './stores/chat/saga';
import roomSaga from './stores/room/saga';
import messageSaga from './stores/messages/saga';
import profileSaga from './stores/profile/saga';
import visitorsSaga from './stores/visitors/saga';
import statisticsSaga from './stores/statistics/saga';

/**
 * @file saga.js
 * @author Daniel Felipe Lucumi Marin
 * @description Initialize general saga
 */

export default function* root() {
  yield all([
    fork(authSaga),
    fork(chatSaga),
    fork(roomSaga),
    fork(messageSaga),
    fork(profileSaga),
    fork(visitorsSaga),
    fork(statisticsSaga),
  ]);
}
