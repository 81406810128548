/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import { put, call, takeLatest } from 'redux-saga/effects';

// Redux
import { setResponse } from 'redux/stores/app/actions';

// Utils
import request from 'utils/request';

// Constants
import {
  GET_USER_INFO,
  SAVE_USER_INFO,
  CHANGE_USER_PASS,
  GET_USERS_ONLINE,
  CHANGE_USER_COUNSELOR,
} from './constants';

/**
* @file saga.js
* @author Daniela Perea
* @description profile saga.
*/

function* getUsersInfo(action) {
  const {
    cb,
    payload: { id },
  } = action;

  const url = `/user/${id}`;
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}
function* getUsersOnline(action) {
  const {
    cb,
  } = action;

  const url = '/user/get-all-connected';
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* saveUsersInfo(action) {
  const {
    cb,
    payload: { id, data },
  } = action;
  const url = `/user/${id}`;
  const requestOptions = { method: 'PUT', body: JSON.stringify(data) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* changeUserPassword(action) {
  const {
    cb,
    payload: { id, data },
  } = action;
  const url = `/user/password/${id}`;
  const requestOptions = { method: 'PUT', body: JSON.stringify(data) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}
function* changeUserCounselor(action) {
  const {
    cb,
    payload: {
      user, visitor, room, sender,
    },
  } = action;
  const urlVisitor = `/visitor/${visitor}`;
  const urlRoom = `/room/${room}`;
  const urlStatistcs = '/statistics/update-user';
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify({
      user, visitor, room, sender,
    }),
  };

  try {
    const visitorResponse = yield call(request, urlVisitor, requestOptions);
    const roomResponse = yield call(request, urlRoom, requestOptions);
    const statistcsResponse = yield call(request, urlStatistcs, requestOptions);
    yield put(setResponse('success', false, false, { roomResponse, visitorResponse, statistcsResponse }, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

/**
* Declare all component sagas
*/

export default function* rootSaga() {
  yield takeLatest(GET_USER_INFO, getUsersInfo);
  yield takeLatest(SAVE_USER_INFO, saveUsersInfo);
  yield takeLatest(CHANGE_USER_PASS, changeUserPassword);
  yield takeLatest(GET_USERS_ONLINE, getUsersOnline);
  yield takeLatest(CHANGE_USER_COUNSELOR, changeUserCounselor);
}
