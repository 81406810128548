/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
// import { logger } from 'redux-logger';
import rootSagas from './saga';
import RootReducer from './reducer';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Redux store.
 */

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  RootReducer,
  applyMiddleware(sagaMiddleware),
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSagas);
export default store;
