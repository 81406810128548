/* eslint-disable no-unused-vars */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { number } from 'prop-types';
import { useParams } from 'react-router-dom';

// Containers
import { SocketContext } from 'containers/context/socket';

// Assets
import ColdEmoji from 'assets/images/cold.png';
import WarmEmoji from 'assets/images/warm.png';
import HotEmoji from 'assets/images/hot.png';

// Components
import Video from 'components/video';
import TopChat from 'components/chat/top';

// Utils
import { EventEmitter } from 'utils/emitter';
import { fireUpdates } from 'utils/eventHandle';
import {
  AlertNotification,
  handleRenderTranslation,
  swalAlert,
  scrollToBottom,
} from 'utils/helpers';
import BodyChat from 'components/chat/body';
import ComposeMessage from 'components/chat/compose';
import ReactAudioPlayer from 'react-audio-player';
import { MdPermMedia, MdCloudDownload } from 'react-icons/md';
import Swal from 'sweetalert2';
import { BitlyClient } from 'bitly-react';
import handleMessage from 'containers/chat/handleMessage';

/**
 * @file view.js
 * @author Brayan David Coy
 * @description Chat container.
 */

const getPaginateMessages = async (getMessages, page, id) => {
  await getMessages(page, id);
};

const handleNewMessage = (type, event, data, addMessageFn, renderMessagesFn) => {
  if (event) {
    const response = fireUpdates(type, event, data.messages);
    const newData = data;
    addMessageFn(event);
    newData.messages = response;
    renderMessagesFn(newData);
  }
};

function Chat({
  globalActions,
  messageActions,
  msgData,
  isDisabled,
  user,
  status,
  roomActions: { getSelectedRooms },
  chatActions: { getShortcodes, sendAdviserCopy, getCmUrl },
  statisticsActions: {
    updateChatStatistic,
    updateVideocallStatistic,
    createVideocallStatistic,
    updateSharedStatistic,
    createSharedStatistic,
    createLevelStatistic,
  },
  visitorId,
  visitorLang,
  replayMessage,
  visitorStatus,
  visitorOnline,
  visitorChating,
  visitorEmail,
}) {
  const socket = useContext(SocketContext);
  const { id } = useParams();
  const { t } = useTranslation();
  const { page, messages, totalPages } = msgData;
  const [roomsUser, setRoomsUser] = useState([]);

  const [optionMessage, setOptionMessage] = useState(false);

  const bitly = new BitlyClient('<accessToken>', {});

  useEffect(() => {
    getPaginateMessages(messageActions.getMessages, page, id);
  }, [id]);

  useEffect(() => {
    if (!optionMessage) {
      getPaginateMessages(messageActions.getMessages, page, id);
    }
  }, [optionMessage]);

  const getUserRooms = () => {
    getSelectedRooms(id, (type, data) => {
      if (type === 'success') {
        setRoomsUser(data);
        globalActions.setVisitorSelected(
          visitorId,
          visitorLang,
          data.visitor.status,
          data.visitor.online,
          data.visitor.is_chating,
          data.visitor.email,
        );
      }
    });
  };

  // useEffect(() => {
  //   if (!visitorOnline) {
  //     updateChatStatistic(visitorId, (type, response) => {
  //       if (type === 'success') {
  //         console.log('update counseling time');
  //       }
  //     });
  //   }
  // }, [visitorOnline]);

  useEffect(() => {
    EventEmitter.subscribe('add_message', event => handleNewMessage(
      'add_message',
      event,
      msgData,
      messageActions.addMessages,
      messageActions.renderMessages,
    ));

    EventEmitter.subscribe('add_room_chat', () => {
      getUserRooms();
    });
    EventEmitter.subscribe('remove_room_chat', () => {
      getUserRooms();
    });
    EventEmitter.subscribe('visitor_chat', () => {
      getUserRooms();
    });
    EventEmitter.subscribe('leave_visitor_chat', () => {
      getUserRooms();
    });
    EventEmitter.subscribe('get_is_chating_chat', () => {
      getUserRooms();
    });

    return () => {
      EventEmitter.unsubscribe('add_message');
      EventEmitter.unsubscribe('add_room_chat');
      EventEmitter.unsubscribe('remove_room_chat');
      EventEmitter.unsubscribe('visitor_chat');
      EventEmitter.unsubscribe('leave_visitor_chat');
      EventEmitter.unsubscribe('get_is_chating_chat');
    };
  });

  const sendCalendly = (visitor, url) => socket.emit('SEND_CALENDLY', visitor, url);

  const chatEnded = async () => {
    const { value: exists } = await Swal.fire({
      title: 'how would you rate this visitor?',
      input: 'radio',
      inputOptions: {
        Cold: `<div class="emoji-div"><img class="img-emoji" src=${ColdEmoji} alt=""/><h1>Cold</h1></div>`,
        Warm: `<div class="emoji-div"><img class="img-emoji" src=${WarmEmoji} alt=""/><h1>Warm</h1></div>`,
        Hot: `<div class="emoji-div"><img class="img-emoji" src=${HotEmoji} alt=""/><h1>Hot</h1></div>`,
      },
      grow: 'row',
      width: '50%',
      showCancelButton: false,
      // eslint-disable-next-line consistent-return
      inputValidator: value => {
        if (!value) {
          return 'You need to choose something!';
        }
      },
    });

    if (exists) {
      Swal.fire(`You selected: ${exists}`);
      createLevelStatistic(visitorId, exists);
    }
  };

  const sendSurvey = visitor => {
    socket.emit('SEND_SURVEY', visitor, (data, mssg) => {
      if (data === 'success') {
        updateChatStatistic(visitor, (type, response) => {
          if (type === 'success') {
            getUserRooms();
          }
        });
      }
    });
  };

  const sendURL = message => {
    const messageResponse = handleMessage('Link', message, id, user.id);
    messageActions.sendMessage(messageResponse, visitorLang, type => {
      if (type === 'success') {
        messageActions.renderMessages([...messages, messageResponse]);
        scrollToBottom();
      }
    });
  };
  const getURL = async () => {
    getCmUrl(visitorEmail, (type, response) => {
      if (type === 'success') {
        const result = `https://backendvc.lae-edu.com/login-chat/${response.user.first_name}/${response.user.email}/${response.access_token}`;
        sendURL(result);
      }
    });
  };

  const handleSendOption = async (type, message, Fn) => {
    const result = await swalAlert(message);
    if (result) {
      switch (type) {
        case 'Calendly':
          sendCalendly(visitorId, user.calendly_url);
          return AlertNotification('success', message);
        case 'Survey':
          sendSurvey(visitorId);
          chatEnded();
          return AlertNotification('success', message);
        case 'AdviserCopy':
          Fn(messages, sendAdviserCopy, visitorId, user.id, id);
          return AlertNotification('success', message);
        case 'Video':
          Fn();
          return AlertNotification('success', message);
        case 'urlCM':
          getURL();
          return AlertNotification('success', message);
        default:
          return true;
      }
    }
    return true;
  };

  const handleRenderMessageContent = (item, isReply) => {
    switch (item.type) {
      case 'Text':
        return handleRenderTranslation(item);
      case 'Audio':
        return (
          <ReactAudioPlayer
            src={item.content}
            autoPlay={false}
            controls
            style={{ width: '150px', height: isReply ? '10px' : '50px' }}
          />
        );
      case 'Image':
        return (
          <>
            {isReply ? (
              <MdPermMedia
                size={20}
                className="outline-none cursor-pointer"
                color="#34A3D4"
                onClick={() => window.open(item.content)}
              />
            ) : (
              <img
                src={item.content}
                className="border-2 rounded-lg"
                alt="message-img"
                width="80%"
              />
            )}
          </>
        );
      case 'File':
        return !isReply ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            className="flex flex-col items-center"
            href={item.content}
            target="_blank"
            download
          >
            <MdCloudDownload size={30} />
            {!isReply && <h6>{t('btn-download')}</h6>}
          </a>
        ) : (
          <MdCloudDownload
            size={20}
            className="outline-none cursor-pointer"
            color="#34A3D4"
            onClick={() => window.open(item.content)}
          />
        );
      case 'Link':
        return (
          <a
            className="flex flex-col items-center underline"
            href={item.content}
            target="_blank"
            download
            rel="noreferrer"
          >
            Link to CM
          </a>
        );
      case 'Component':
        return (
          <iframe
            src={item.content}
            title="prospection"
            frameBorder="0"
            border="0"
            cellSpacing="0"
            width="85%"
            height="100%"
            fontSize="small"
          />
        );
      default:
        return true;
    }
  };

  return (
    <>
      <div className="flex justify-between flex-col flex-1">
        <div className="flex justify-between items-center border-b border-gray-200 h-16 px-5 z-10">
          {!isDisabled && (
            <>
              {status ? (
                <>
                  {visitorOnline && (
                  <>
                    <TopChat handleSendOption={handleSendOption} />
                    <Video
                      user={user}
                      visitorId={visitorId}
                      handleSendOption={handleSendOption}
                      updateVideocallStatistic={updateVideocallStatistic}
                      createVideocallStatistic={createVideocallStatistic}
                      updateSharedStatistic={updateSharedStatistic}
                      createSharedStatistic={createSharedStatistic}
                    />
                  </>
                  )}

                </>
              ) : (
                <h6>{t('offline-message-top-chat')}</h6>
              )}
            </>
          )}
        </div>
        <BodyChat
          messages={messages}
          page={page}
          roomId={id}
          visitorId={visitorId}
          totalPages={totalPages}
          globalActions={globalActions}
          setOptionMessage={setOptionMessage}
          handleRenderMessageContent={handleRenderMessageContent}
          optionMessage={optionMessage}
          messageActions={messageActions}
        />
        <ComposeMessage
          isDisabled={isDisabled}
          replayMessage={replayMessage}
          optionMessage={optionMessage}
          messageActions={messageActions}
          globalActions={globalActions}
          user={user}
          id={id}
          setOptionMessage={setOptionMessage}
          messages={messages}
          visitorLang={visitorLang}
          visitorId={visitorId}
          visitorStatus={visitorStatus}
          visitorOnline={visitorOnline}
          visitorChating={visitorChating}
          getShortcodes={getShortcodes}
          handleRenderMessageContent={handleRenderMessageContent}
          status={status}
        />
      </div>
    </>
  );
}

/**
 * Proptypes definition.
 */

Chat.propTypes = {
  globalActions: PropTypes.shape({
    setReplayMessage: PropTypes.func,
    setVisitorSelected: PropTypes.func,
  }).isRequired,
  messageActions: PropTypes.shape({
    getMessages: PropTypes.func,
    sendMessage: PropTypes.func,
    addMessages: PropTypes.func,
    renderMessages: PropTypes.func,
  }).isRequired,
  roomActions: PropTypes.shape({
    getSelectedRooms: PropTypes.func,
    hideRoom: PropTypes.func,
  }).isRequired,
  isDisabled: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  chatActions: PropTypes.shape({
    getShortcodes: PropTypes.func,
    sendAdviserCopy: PropTypes.func,
    getCmUrl: PropTypes.func,
  }).isRequired,
  statisticsActions: PropTypes.shape({
    updateChatStatistic: PropTypes.func,
    updateVideocallStatistic: PropTypes.func,
    createVideocallStatistic: PropTypes.func,
    updateSharedStatistic: PropTypes.func,
    createSharedStatistic: PropTypes.func,
    createLevelStatistic: PropTypes.func,
  }).isRequired,
  visitorId: PropTypes.string.isRequired,
  visitorLang: PropTypes.string.isRequired,
  visitorStatus: PropTypes.bool.isRequired,
  visitorOnline: PropTypes.bool.isRequired,
  visitorChating: PropTypes.bool.isRequired,
  visitorEmail: PropTypes.string.isRequired,
  replayMessage: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.bool.isRequired,
  msgData: PropTypes.shape({
    page: PropTypes.number,
    messages: PropTypes.arrayOf(PropTypes.any),
    totalPages: PropTypes.number,
  }).isRequired,
};

Chat.defaultProps = {
  isDisabled: false,
};

export default Chat;
