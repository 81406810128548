/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

export const AUTH_LOGIN = 'AUTH/AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH/AUTH_LOGOUT';
export const AUTH_CHECK = 'AUTH/AUTH_CHECK';
export const USER_OFFLINE = 'AUTH/USER_OFFLINE';
