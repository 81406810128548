/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import { put, call, takeLatest } from 'redux-saga/effects';

// Redux
import { setResponse, setUser } from 'redux/stores/app/actions';

// Utils
import request from 'utils/request';

// Constants
import {
  AUTH_LOGIN, AUTH_LOGOUT, USER_OFFLINE,
} from './constants';

/**
 * @author Daniel Felipe Lucumi Marin
 * @file request.js
 * @description Execute request
 */

function* login(action) {
  const { cb, payload } = action;
  const url = '/auth/signin';
  const requestOptions = { method: 'POST', body: JSON.stringify(payload) };
  try {
    const response = yield call(request, url, requestOptions);
    yield put(setUser(response));
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* logout(action) {
  const { cb, payload } = action;
  const url = '/auth/logout';
  const requestOptions = { method: 'PUT', body: JSON.stringify(payload) };
  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* setUserStatus(action) {
  const {
    cb,
    payload: { id, online },
  } = action;

  const url = `/user/status/${id}`;
  const requestOptions = { method: 'PUT', body: JSON.stringify({ online }) };
  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

/**
 * Declare all component sagas
 */

export default function* rootSaga() {
  yield takeLatest(AUTH_LOGIN, login);
  yield takeLatest(AUTH_LOGOUT, logout);
  yield takeLatest(USER_OFFLINE, setUserStatus);
}
