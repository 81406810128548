/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import { RENDER_MESSAGES, ADD_MESSAGE, RESET_CHAT } from './constants';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Redux store.
 */

const initialState = {
  messages: [],
  page: 1,
  totalItems: 0,
  totalPages: 0,
};

const handleAddMessageToReducer = (state, messages) => {
  const tmpState = [...messages, ...state];
  const hash = {};
  const newState = tmpState.filter(current => {
    const exists = !hash[current.id];
    hash[current.id] = true;
    return exists;
  });
  return newState;
};

const Messages = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case RENDER_MESSAGES:
      return {
        ...state,
        messages: payload.page === 1
          ? payload.messages
          : handleAddMessageToReducer(state.messages, payload.messages),
        page: payload.page,
        totalItems: payload.totalCount,
        totalPages: payload.totalPages,
      };
    case ADD_MESSAGE:
      return {
        ...state,
        messages: state.messages.concat(payload),
      };
    case RESET_CHAT:
      return initialState;
    default:
      return state;
  }
};

export default Messages;
