/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { MdEmail, MdPublic, MdLock } from 'react-icons/md';

// Utils
import { renderError } from 'utils/helpers';

// Scheme
import schema from './formConfig';

/**
 * @file index.jsx
 * @author Daniel Felipe Lucumi Marin
 * @description Login form component.
 */

function LoginForm({ logo, handleSubmitFn }) {
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = data => handleSubmitFn(data);

  const changeLanguageHandler = e => {
    i18n.changeLanguage(e.target.value);
  };

  useEffect(() => {
    i18n.changeLanguage('en');
  }, []);

  return (
    <form
      className="p-10 flex justify-center items-center flex-col xl:w-5/12 lg:w-5/12 md:w-11/12 sm:w-full w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <img className="mb-10" src={logo} alt="login-logo" />
      <div className="flex items-center border-b border-teal-500 my-2 w-full">
        <MdEmail className="text-white" />
        <input
          type="email"
          name="email"
          className="input-login"
          placeholder={t('input-email')}
          ref={register}
          autoComplete="off"
        />
      </div>
      {errors.email && renderError(errors.email.message)}
      <div className="flex items-center border-b border-teal-500 my-2 w-full">
        <MdLock className="text-white" />
        <input
          type="password"
          name="password"
          className="input-login"
          placeholder={t('input-password')}
          ref={register}
          autoComplete="off"
        />
      </div>
      {errors.password && renderError(errors.password.message)}
      <div className="flex items-center border-b border-teal-500 my-2 w-full">
        <MdPublic className="text-white" />
        <select
          onChange={e => changeLanguageHandler(e)}
          className="block appearance-none w-full bg-transparent text-gray-400 px-2 py-2 pr-8 rounded focus:outline-none"
        >
          <option value="en">{t('language-en')}</option>
          <option className="py-2 bg-gray-700 text-white" value="es">
            {t('language-es')}
          </option>
        </select>
      </div>
      <button
        className="btn-login"
        type="submit"
      >
        <span>{t('btn-login')}</span>
      </button>
    </form>
  );
}

/**
 * Proptypes definition.
 */
LoginForm.propTypes = {
  logo: PropTypes.string.isRequired,
  handleSubmitFn: PropTypes.func.isRequired,
};

export default LoginForm;
