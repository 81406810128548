/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import {
  GET_VISITORS_INFO,
} from './constants';

/**
 * @file actions.js
 * @author Daniela Perea
 * @description profile actions.
 */

const getVisitorsInfo = (data, cb) => ({
  type: GET_VISITORS_INFO,
  payload: data,
  cb,
});

export default getVisitorsInfo;
