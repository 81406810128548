/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import { put, call, takeLatest } from 'redux-saga/effects';

// Redux
import { setResponse } from 'redux/stores/app/actions';

// Utils
import request from 'utils/request';

// Constants
import {
  GET_VISITORS,
  GET_SHORTCODES,
  GET_LANGUAGES,
  SAVE_SHORTCODE,
  GET_WP_QR,
  SEND_ADVISER_COPY,
  GET_ALL_SHORTCODES,
  DELETE_SHORTCODE,
  GET_CM_URL,
} from './constants';

/**
 * @file saga.js
 * @author Daniel Felipe Lucumi Marin
 * @description Execute request
 */

function* getVisitors(action) {
  const { cb } = action;
  const url = '/chat/visitors';
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* getShortcodes(action) {
  const {
    cb,
    payload: { page, code },
  } = action;

  const offset = page * 10;
  const url = `/chat/shortcodes?limit=10&offset=${offset}&code=${code}`;
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* getAllShortcodes(action) {
  const {
    cb,
    payload: { page },
  } = action;

  const offset = page * 10;
  const url = `/chat/shortcodes-all?limit=10&offset=${offset}`;
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* getLanguages(action) {
  const { cb } = action;
  const url = '/chat/languages';
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* saveShortCode(action) {
  const { cb, payload } = action;
  const url = '/chat/shortcodes';
  const requestOptions = { method: 'POST', body: JSON.stringify(payload) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* getQRcode(action) {
  const { cb } = action;
  const url = '/chat/whatsapp/auth';
  const requestOptions = { method: 'GET' };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* sendAdviserCopy(action) {
  const { cb, payload } = action;
  const url = '/chat/adviser-copy';
  const requestOptions = { method: 'POST', body: JSON.stringify(payload) };

  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* deleteShortcode(action) {
  const { payload, cb } = action;
  const url = `/chat/shortcodes/delete/${payload}`;
  const requestOptions = { method: 'DELETE' };
  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

function* getCmUrl(action) {
  const { payload, cb } = action;
  const url = `http://corecm.torrens-australia.local/auth/login-chat?email=${payload}`;
  const requestOptions = { externalUrl: true, method: 'POST' };
  try {
    const response = yield call(request, url, requestOptions);
    yield put(setResponse('success', false, false, response, cb));
  } catch (error) {
    yield put(setResponse('error', false, false, error, cb));
  }
}

/**
 * Declare all component sagas
 */

export default function* rootSaga() {
  yield takeLatest(GET_VISITORS, getVisitors);
  yield takeLatest(GET_SHORTCODES, getShortcodes);
  yield takeLatest(GET_ALL_SHORTCODES, getAllShortcodes);
  yield takeLatest(GET_LANGUAGES, getLanguages);
  yield takeLatest(SAVE_SHORTCODE, saveShortCode);
  yield takeLatest(GET_WP_QR, getQRcode);
  yield takeLatest(SEND_ADVISER_COPY, sendAdviserCopy);
  yield takeLatest(DELETE_SHORTCODE, deleteShortcode);
  yield takeLatest(GET_CM_URL, getCmUrl);
}
