/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NotificationBadge, { Effect } from 'react-notification-badge';
import NotificationSound from 'assets/audio/notification.wav';

// const soundUrl = 'https://ia800203.us.archive.org/14/items/slack_sfx/been_tree.mp3';
const notificationSound = new Audio(NotificationSound);

const Notification = ({ count }) => {
  useEffect(() => {
    notificationSound.play();
  }, [count]);

  return (<NotificationBadge count={count} effect={Effect.SCALE} />);
};

/**
 * Proptypes definition.
 */
Notification.propTypes = {
  count: PropTypes.number.isRequired,
};

export default Notification;
