/* eslint-disable no-unused-vars */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppLayout from 'containers/layout';
import { SiAddthis } from 'react-icons/si';

// Components
import ShortcodeAdmin from 'containers/shortcodes';
import FormShortcode from 'components/forms/shortCode';
import Modal from 'components/modal';

// Utils
import { AlertNotification } from 'utils/helpers';

/**
 * @file view.js
 * @author Daniel Felipe Lucumi Marin
 * @description Home page.
 */

const getLanguageData = (getLanguages, setLanguages) => {
  getLanguages((type, data) => {
    if (type === 'success') {
      setLanguages(data);
    }
  });
};

function Setting({
  chatActions: {
    getLanguages,
    createShortCode,
    getAllShortcodes,
    deleteShortcode,
  },
}) {
  const [modal, setModal] = useState(false);
  const [languages, setLanguages] = useState(false);
  const { t } = useTranslation();
  const [shortCodes, setShortCodes] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getLanguageData(getLanguages, setLanguages);
  }, []);

  const getItems = () => getAllShortcodes(page, (type, data) => {
    if (type === 'success') {
      setShortCodes(data);
    }
  });

  useEffect(() => {
    getItems();
  }, [page]);

  const handleSUbmit = formData => {
    createShortCode(formData, (type, data) => {
      if (type === 'success') {
        AlertNotification(data.type, data.msg);
        setModal(false);
        getLanguageData(getLanguages, setLanguages);
        getItems();
      }
    });
  };

  return (
    <AppLayout title={t('settings-title')}>
      <section className="flex min-h-0 flex-col flex-auto border-l">
        <div className="flex-1 rounded-lg overflow-hidden shadow-lg bg-white w-12/12">
          <div className="flex flex-col">
            <div className="flex flex-row px-6 py-4 border-b">
              <div className="text-xl flex-grow">{t('settings-subtitle')}</div>
              <button
                type="button"
                className="flex-none"
                onClick={() => setModal(true)}
              >
                <SiAddthis size="18" />
              </button>
            </div>
            <div className="p-2 flex-grow">
              <ShortcodeAdmin
                shortCodes={shortCodes}
                deleteShortcode={deleteShortcode}
                getItems={getItems}
              />
            </div>
          </div>
        </div>
        {modal && (
          <Modal
            title={t('settings-title')}
            isOpen={modal}
            action={setModal}
            size="w-2/6"
          >
            <FormShortcode lang={languages} submitForm={handleSUbmit} />
          </Modal>
        )}
      </section>
    </AppLayout>
  );
}

/**
 * Proptypes definition.
 */
Setting.propTypes = {
  chatActions: PropTypes.shape({
    getLanguages: PropTypes.func,
    createShortCode: PropTypes.func,
    getAllShortcodes: PropTypes.func.isRequired,
    deleteShortcode: PropTypes.func.isRequired,
  }).isRequired,
};

export default Setting;
