/* eslint-disable prefer-destructuring */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MoonLoader from 'react-spinners/SyncLoader';
import { Redirect } from 'react-router-dom';

// Components
import LoginForm from 'components/forms/login';

// Context
import { SocketContext } from 'containers/context/socket';

// Assets
import loginBackground from 'assets/images/Bck-Login-Admin-2021.jpg';
import TUALogo from 'assets/images/Logo-LAE.png';

/**
 * @file desktopView.js
 * @author Brayan David Coy
 * @description desktop view layout component.
 */

function Login({
  globalActions, authActions: { authLogin }, loading, isAuthenticated, user,
}) {
  const socket = useContext(SocketContext);
  const handleSubmit = data => {
    const newData = data;
    newData.lang = navigator.language.split('-')[0];
    globalActions.setLoading(true);
    authLogin(newData, () => {
      globalActions.setLoading(false);
    });
  };

  if (isAuthenticated) {
    socket.emit('onUserDisconnect', user.id);
    return <Redirect to={`${user.type === 'Admin' ? '/statistics' : '/home'}`} />;
  }

  return (
    <div className="w-screen h-screen flex items-center bg-primary relative overflow-hidden">
      <img
        src={loginBackground}
        alt="login-background"
        className="absolute h-full w-full object-cover"
      />
      <div className="container mx-auto px-6 md:px-12 relative z-10 flex items-center justify-end py-32 xl:py-40">
        <LoginForm
          handleSubmitFn={handleSubmit}
          logo={TUALogo}
        />
      </div>
      <div className="absolute right-10 bottom-10">
        <MoonLoader color="#9DEEEF" loading={loading} size={10} />
      </div>
    </div>
  );
}

/**
 * Proptypes definition.
 */
Login.propTypes = {
  globalActions: PropTypes.shape({
    setLoading: PropTypes.func,
  }).isRequired,
  authActions: PropTypes.shape({
    authLogin: PropTypes.func,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Login;
