/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import Peer from 'simple-peer';
import { Howl } from 'howler';
import PropTypes from 'prop-types';
import { GiVideoConference } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import Swal from 'sweetalert2';

// Containers
import { SocketContext } from 'containers/context/socket';

// Utils
import { AlertNotification, RTCNegotiationServers } from 'utils/helpers';

// Assets
import { AiFillCloseCircle, AiOutlineFundProjectionScreen } from 'react-icons/ai';
import ringtoneCall from 'assets/audio/ringtone.mp3';

/**
 * @file index.js
 * @author Brayan David Coy
 * @description Video chat component
 */

const ringtoneSound = new Howl({
  src: [ringtoneCall],
  preload: true,
});

export default function Video({
  user, visitorId, handleSendOption,
  updateVideocallStatistic, createVideocallStatistic,
  updateSharedStatistic, createSharedStatistic,
}) {
  const socket = useContext(SocketContext);
  const [stream, setStream] = useState();
  const [isStreaming, setIsStreaming] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [cameraPermissions, setCameraPermissions] = useState(null);
  const [activeDrags, setActiveDrags] = useState(0);
  const { t } = useTranslation();
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();

  const getCameraPermissions = () => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(streamFn => {
      setStream(streamFn);
      setCameraPermissions(true);
      myVideo.current.srcObject = streamFn;
    }).catch(() => {
      setCameraPermissions(false);
    });
  };

  useEffect(() => {
    getCameraPermissions();
  }, []);

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  const dragHandlers = { onStart, onStop };

  const videoCallExists = async () => {
    const { value: exists } = await Swal.fire({
      title: 'What did you shared with the visitor?',
      input: 'radio',
      inputOptions: {
        counselor: 'Counselor Module',
        Home_page: 'University Home page',
        other: 'Other',
      },
      grow: 'row',
      width: '50%',
      showCancelButton: false,
      // eslint-disable-next-line consistent-return
      inputValidator: value => {
        if (!value) {
          return 'You need to choose something!';
        }
      },
    });

    if (exists) {
      Swal.fire(`You selected: ${exists}`);
      updateSharedStatistic(visitorId, exists);
    }
  };

  const gotMedia = streamFn => {
    connectionRef.current.replaceTrack(stream.getVideoTracks()[0], streamFn.getVideoTracks()[0], stream);
    myVideo.current.srcObject = streamFn;
    setIsStreaming(true);
    createSharedStatistic(visitorId);

    streamFn.getTracks()[0].onended = () => {
      connectionRef.current.replaceTrack(streamFn.getVideoTracks()[0], stream.getVideoTracks()[0], stream);
      myVideo.current.srcObject = stream;
      setIsStreaming(false);
      videoCallExists();
    };
  };

  useEffect(() => {
    socket.emit('streamUser', {
      userToCall: visitorId,
      stream: isStreaming,
    });
  }, [isStreaming]);

  const shareScreen = () => {
    navigator.mediaDevices.getDisplayMedia({ cursor: true }).then(gotMedia);
  };

  const leaveCall = () => {
    setCallEnded(true);
    setCallAccepted(false);
    connectionRef.current.destroy();
    socket.emit('callEnded');
    updateVideocallStatistic(visitorId);
    window.location.reload();
  };

  const callUser = () => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
      config: {
        iceServers: RTCNegotiationServers,
      },
    });

    connectionRef.current = peer;

    peer.on('signal', data => {
      socket.emit('callUser', {
        userToCall: visitorId,
        signalData: data,
        from: user.id,
        name: user.name,
        picture: user.picture,
      });
      ringtoneSound.play();
    });

    peer.on('stream', streamFn => {
      if (userVideo.current) {
        userVideo.current.srcObject = streamFn;
      }
    });

    socket.on('callAccepted', signal => {
      peer.signal(signal);
      setCallAccepted(true);
      createVideocallStatistic(visitorId);
      ringtoneSound.unload();
    });

    socket.on('callEnded', () => {
      leaveCall();
      updateVideocallStatistic(visitorId);
    });
  };

  return (
    <>
      <span className="flex items-center justify-center rounded-full bg-blue-500 hover:bg-blue-700 p-3 text-white z-10">
        <GiVideoConference
          className="cursor-pointer w-5 h-5"
          onClick={() => {
            if (!callAccepted || callEnded) {
              if (!cameraPermissions) {
                AlertNotification('error', 'You need camera permissions 😥');
              } else {
                handleSendOption('Video', t('alert-message-5'), callUser);
              }
            } if (callAccepted && !callEnded) {
              handleSendOption('Video', t('alert-message-6'), leaveCall);
            }
          }}
          title="Start videoCall"
        />
      </span>
      <Draggable {...dragHandlers} cancel=".close">
        <div className={`${isStreaming ? 'rcw-videocall-container-stream' : 'rcw-videocall-container'}`} style={{ visibility: callAccepted ? 'visible' : 'hidden' }}>
          <div className="rcw-videocall-intro-container">
            {callAccepted && !callEnded && (
            <>
              <video playsInline ref={userVideo} autoPlay style={isStreaming ? { width: '200px', height: '200px' } : { width: '500px', marginRight: '30px', height: '500px' }} />
              <button type="button" className="close-icon-videocall close" onClick={() => leaveCall()}>
                <AiFillCloseCircle size={30} color="#e06c00" />
              </button>
              <button
                type="button"
                className="share-icon-videocall close"
                onClick={() => {
                  shareScreen();
                }}
              >
                <AiOutlineFundProjectionScreen size={30} color="#e06c00" />
              </button>
            </>
            )}
            {stream && (
            <video
              playsInline
              muted
              ref={myVideo}
              autoPlay
              style={isStreaming ? {
                width: '1000px', height: '600px', visibility: callAccepted ? 'visible' : 'hidden',
              } : {
                width: '500px', height: '500px', visibility: callAccepted ? 'visible' : 'hidden',
              }}
            />
            )}
          </div>
        </div>
      </Draggable>
    </>
  );
}

Video.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  visitorId: PropTypes.string.isRequired,
  updateVideocallStatistic: PropTypes.func.isRequired,
  createVideocallStatistic: PropTypes.func.isRequired,
  updateSharedStatistic: PropTypes.func.isRequired,
  createSharedStatistic: PropTypes.func.isRequired,
  handleSendOption: PropTypes.func.isRequired,
};
