/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import {
  GET_USER_INFO,
  SAVE_USER_INFO,
  CHANGE_USER_PASS,
  GET_USERS_ONLINE,
  CHANGE_USER_COUNSELOR,
} from './constants';

/**
 * @file actions.js
 * @author Daniela Perea
 * @description profile actions.
 */

export const getUsersInfo = (id, cb) => ({
  type: GET_USER_INFO,
  payload: { id },
  cb,
});

export const saveUsersInfo = (id, data, cb) => ({
  type: SAVE_USER_INFO,
  payload: { id, data },
  cb,
});

export const changeUserPassword = (id, data, cb) => ({
  type: CHANGE_USER_PASS,
  payload: { id, data },
  cb,
});

export const getUsersOnline = cb => ({
  type: GET_USERS_ONLINE,
  cb,
});

export const changeUserCounselor = (user, visitor, room, sender, cb) => ({
  type: CHANGE_USER_COUNSELOR,
  payload: {
    user, visitor, room, sender,
  },
  cb,
});
