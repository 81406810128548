/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import {
  GET_GENERAL_STATISTICS,
  GET_STATISTICS,
  UPDATE_CHAT_STATISTICS,
  UPDATE_VIDEOCALL_STATISTICS,
  CREATE_VIDEOCALL_STATISTICS,
  CREATE_SHARED_STATISTICS,
  UPDATE_SHARED_STATISTICS,
  CREATE_LEVEL_STATISTICS,
  CREATE_LEADS_STATISTICS,
} from './constants';

/**
 * @file actions.js
 * @author Daniela Perea
 * @description profile actions.
 */

export const getStatistics = (start, end, cb) => ({
  type: GET_STATISTICS,
  payload: { start, end },
  cb,
});

export const getGeneralStatistics = (start, end, cb) => ({
  type: GET_GENERAL_STATISTICS,
  payload: { start, end },
  cb,
});

export const updateChatStatistic = (id, cb) => ({
  type: UPDATE_CHAT_STATISTICS,
  payload: id,
  cb,
});

export const updateVideocallStatistic = (id, cb) => ({
  type: UPDATE_VIDEOCALL_STATISTICS,
  payload: id,
  cb,
});

export const createVideocallStatistic = (id, cb) => ({
  type: CREATE_VIDEOCALL_STATISTICS,
  payload: id,
  cb,
});

export const updateSharedStatistic = (id, type, cb) => ({
  type: UPDATE_SHARED_STATISTICS,
  payload: { id, type },
  cb,
});

export const createSharedStatistic = (id, cb) => ({
  type: CREATE_SHARED_STATISTICS,
  payload: id,
  cb,
});

export const createLevelStatistic = (id, type, cb) => ({
  type: CREATE_LEVEL_STATISTICS,
  payload: { id, type },
  cb,
});

export const createLeadsStatistic = (start, end, cb) => ({
  type: CREATE_LEADS_STATISTICS,
  payload: { start, end },
  cb,
});
