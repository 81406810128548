/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/**
 *
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import PropTypes from 'prop-types';
import moment from 'moment';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import {
  FiUserX, FiUserCheck, FiTrash, FiUsers,
} from 'react-icons/fi';

// Containers
import { SocketContext } from 'containers/context/socket';

// components
import Notification from 'components/notification';
import Modal from 'components/modal';

// Utils
import { handleResetChat } from 'utils/helpers';
import { EventEmitter } from 'utils/emitter';
import { fireUpdates } from 'utils/eventHandle';

/**
 * @file view.js
 * @author Brayan David Coy
 * @description Shortcodes table.
 */

const handleRoom = (type, event, rooms, setRooms, getRooms) => {
  const response = fireUpdates(type, event, rooms);
  setRooms(response);
  getRooms();
};

const handleNotification = (item, notifications) => {
  let countNotify = 0;
  if (!notifications.length) {
    return countNotify;
  }
  const { visitor_id: visitorRoom } = item;
  notifications.forEach(notify => {
    const { visitor_id: visitor } = notify;
    if (visitorRoom === visitor) {
      countNotify += 1;
    }
  });
  return countNotify;
};

function ActiveRooms({
  roomActions: { getMyRooms, hideRoom },
  notifications,
  globalActions: { resetNotification, setVisitorSelected },
  profileActions: { getUsersOnline, changeUserCounselor },
  messageActions: { resetChat },
  user,
  visitorId,
  visitorStatus,
}) {
  const socket = useContext(SocketContext);
  const [rooms, setRooms] = useState([]);
  const { id } = useParams();
  const [modal, setModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [roomSelected, setRoomSelected] = useState();
  const { t } = useTranslation();
  const history = useHistory();

  const getRooms = () => getMyRooms((type, data) => {
    if (type === 'success') {
      setRooms(data);
    }
  });

  useEffect(() => {
    getRooms();
  }, []);

  useEffect(() => {
    getRooms();
  }, [visitorStatus]);

  useEffect(() => {
    EventEmitter.subscribe('add_room', event => {
      handleRoom('add_room', event, rooms, setRooms, getRooms);
    });
    EventEmitter.subscribe('remove_room', event => {
      handleRoom('remove_room', event, rooms, setRooms, getRooms);
    });

    EventEmitter.subscribe('visitor', event => getRooms());
    EventEmitter.subscribe('leave_visitor', event => getRooms());
    EventEmitter.subscribe('get_is_chating', event => getRooms());

    return () => {
      EventEmitter.unsubscribe('add_room');
      EventEmitter.unsubscribe('remove_room');
      EventEmitter.unsubscribe('visitor');
      EventEmitter.unsubscribe('leave_visitor');
      EventEmitter.unsubscribe('get_is_chating');
    };
  }, []);

  const removeItem = row => {
    hideRoom({ id: row.id }, () => {
      handleRoom('remove_room', row, rooms, setRooms, getRooms);
    });
  };

  useEffect(() => {
    getUsersOnline((type, response) => {
      if (type === 'success') {
        let optionsArr = [];
        if (response.length) {
          response.map(userFn => {
            const newOption = {
              value: {
                data: userFn.id,
                type: userFn.type,
              },
              label: `${userFn.first_name} ${userFn.last_name}`,
            };
            return optionsArr.push(newOption);
          });
        }
        setOptions(optionsArr);
      }
    });
  }, [modal]);

  const handleChangeCounselor = option => {
    changeUserCounselor(option.value.data, visitorId, roomSelected, user.id, (type, response) => {
      if (type === 'success') {
        setModal(!modal);
        socket.emit('changeCounselor', option.value.data, visitorId);
        history.push('/home');
        getRooms();
      }
    });
  };

  return (
    <>
      {rooms
        && rooms.map((item, index) => (
          <div>
            <div
              key={`item-active-room-${item.visitor_id}`}
              className="border-r border-t border-b w-full rounded-md mb-4 hover:shadow-sm"
            >
              <div
                className={`border-l-4 relative ${
                  !item.visitor?.online ? 'border-red-400' : 'border-green-500'
                } rounded-md p-5`}
              >
                {!item.visitor?.online && (
                <FiTrash
                  onClick={() => removeItem(item)}
                  className="absolute right-8 top-2 m-2 cursor-pointer text-red-400 w-4 h-4"
                  title="delete ticket"
                />
                )}
                <FiUsers
                  className="absolute right-1 top-2 m-2 cursor-pointer text-red-400 w-4 h-4"
                  onClick={() => setModal(!modal)}
                  title="reassign visitor"
                />
                <article
                  className={`cursor-pointer rounded-md mb-2 flex  focus:outline-none ${
                    id === item.visitor_id && 'border-green-500'
                  }`}
                >
                  <span className="flex-none pt-1 pr-2">
                    {item.visitor?.online ? (
                      <FiUserCheck className="text-green-400" />
                    ) : (
                      <FiUserX className="text-red-400" />
                    )}
                  </span>
                  <div className="flex-1">
                    <header className="mb-1">
                      <NavLink
                        key={index}
                        to={`/home/chat/${item.id}`}
                        onClick={() => {
                          setVisitorSelected(
                            item.visitor_id,
                            item.visitor.lang,
                            item.visitor.status,
                            item.visitor.online,
                            item.visitor.is_chating,
                            item.visitor.email,
                          );
                          setRoomSelected(item.id);
                          resetNotification(item.visitor_id);
                          handleResetChat(id, item.id, resetChat);
                        }}
                        className="text-gray-700 z-50"
                        activeClassName="text-blue-500"
                      >
                        <h1 className="font-ligth">{`${item.visitor?.first_name} ${item.visitor?.last_name}`}</h1>
                        <h3 className="font-thin">{`${item.visitor?.country}, ${item.visitor?.zip}`}</h3>
                      </NavLink>
                      <p className="font-thin">{`${item.visitor?.email}`}</p>
                      <p className="font-thin" style={{ fontSize: '12px' }}>{`${item.visitor?.phone}`}</p>
                    </header>
                    <footer className="text-gray-500 mt-2 text-xs">
                      {!item.status && (
                      <span>
                        {`Disconnected at: ${moment(item.finished_at).format(
                          'MMMM Do YYYY, h:mm a',
                        )}`}
                      </span>
                      )}
                      <div>
                        {notifications.length > 0 && (
                        <Notification
                          count={handleNotification(item, notifications)}
                        />
                        )}
                      </div>
                    </footer>
                  </div>
                </article>
              </div>
            </div>
          </div>
        ))}
      <Modal
        title={t('transfer-message')}
        isOpen={modal}
        action={setModal}
        size="w-2/6"
      >
        <Select
          options={options}
          filterOption={obj => obj.value.type === 'Counselor' && obj.value.data !== user.id}
          onChange={value => handleChangeCounselor(value)}
        />
      </Modal>
    </>
  );
}

/**
 * Proptypes definition.
 */
ActiveRooms.propTypes = {
  globalActions: PropTypes.shape({
    setLoading: PropTypes.func,
    resetNotification: PropTypes.func,
    setVisitorSelected: PropTypes.func,
  }).isRequired,
  messageActions: PropTypes.shape({
    resetChat: PropTypes.func,
  }).isRequired,
  profileActions: PropTypes.shape({
    getUsersOnline: PropTypes.func,
    changeUserCounselor: PropTypes.func,
  }).isRequired,
  roomActions: PropTypes.shape({
    getMyRooms: PropTypes.func,
    hideRoom: PropTypes.func,
  }).isRequired,
  notifications: PropTypes.arrayOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  visitorId: PropTypes.string.isRequired,
  visitorStatus: PropTypes.bool.isRequired,
};

export default ActiveRooms;
