/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import * as yup from 'yup';

/**
 * @file index.jsx
 * @author Brayan David Coy
 * @description form config validation.
 */

const schema = yup.object().shape({
  language_id: yup
    .string()
    .required(),
  code: yup
    .string()
    .required(),
  content: yup
    .string()
    .required(),
});

export default schema;
