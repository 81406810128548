/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import {
  RESET_NOTIFICATION,
  SEND_NOTIFICATION,
  SET_LOADING,
  SET_MENU_OPTION,
  SET_RESPONSE,
  SET_VISITOR,
  SET_REPLAY_MESSAGE,
} from './constants';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Redux store.
 */

const initialState = {
  loading: false,
  error: false,
  notifications: [],
  sidebarOp: 1,
  visitorId: '',
  visitorLang: '',
  visitorStatus: false,
  visitorOnline: false,
  visitorChating: false,
  visitorEmail: '',
  replayMessage: {},
};

const handleResetNotification = (state, visitorToReset) => {
  if (!state.length) {
    return state;
  }
  const newState = state.filter(item => item.visitor_id !== visitorToReset);
  return newState;
};

const Global = persistReducer(
  { storage, key: 'notify', whitelist: ['notifications', 'sidebarOp', 'visitorId', 'visitorLang'] },
  (state = initialState, { type, payload = null }) => {
    switch (type) {
      case SET_LOADING:
        return {
          ...state,
          loading: payload.loading,
        };
      case SET_RESPONSE:
        return {
          ...state,
          error: payload,
        };
      case SEND_NOTIFICATION:
        return {
          ...state,
          notifications: [...state.notifications, { visitor_id: payload.visitor_id }],
        };
      case RESET_NOTIFICATION:
        return {
          ...state,
          notifications: handleResetNotification(state.notifications, payload),
        };
      case SET_MENU_OPTION:
        return {
          ...state,
          sidebarOp: payload,
        };
      case SET_VISITOR:
        return {
          ...state,
          visitorId: payload.id,
          visitorLang: payload.lang,
          visitorStatus: payload.status,
          visitorOnline: payload.online,
          visitorChating: payload.isChating,
          visitorEmail: payload.email,
        };
      case SET_REPLAY_MESSAGE:
        return {
          ...state,
          replayMessage: payload,
        };
      default:
        return state;
    }
  },
);

export default Global;
