/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Assets
import { MdDelete } from 'react-icons/md';

/**
 * @file view.js
 * @author Daniel Felipe Lucumi marin
 * @description Shortcodes table.
 */

function ShortCodes({ shortCodes, deleteShortcode, getItems }) {
  const { t } = useTranslation();

  return (
    <div className="flex-1 overflow-x-auto">
      <div className="min-w-screen bg-gray-100 flex items-center justify-center font-sans overflow-hidden">
        <div className="w-full">
          <div className="bg-white shadow-md rounded p-2 overflow-x-scroll" style={{ overflow: 'auto', width: '100%', height: '600px' }}>
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-center w-3">{t('form-input-7')}</th>
                  <th className="py-3 px-6 text-center">{t('form-input-10')}</th>
                  <th className="py-3 px-6 text-center">{t('form-input-12')}</th>
                  <th className="py-3 px-6 text-center">{t('form-input-14')}</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light h-60 ">
                {
                  shortCodes.length > 0 && shortCodes.map(item => (
                    <tr className="border-b border-gray-200 hover:bg-gray-100" key={`item-${item.id}`}>
                      <td className="py-3 px-6" align="center" style={{ width: '300px' }}>
                        {item.languages.name}
                      </td>
                      <td className="py-3 px-6" align="center" style={{ width: '300px' }}>{item.code}</td>
                      <td className="py-3 px-6" align="center" style={{ width: '500px' }}>
                        {item.content}
                      </td>
                      <td className="py-3 px-6" align="center" style={{ width: '300px' }}>
                        <MdDelete
                          size={15}
                          className="cursor-pointer"
                          onClick={() => deleteShortcode(item.id, type => {
                            if (type === 'success') {
                              getItems();
                            }
                          })}
                        />
                        {' '}
                      </td>
                    </tr>
                  ))
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Proptypes definition.
 */
ShortCodes.propTypes = {
  shortCodes: PropTypes.arrayOf(PropTypes.any).isRequired,
  deleteShortcode: PropTypes.func.isRequired,
  getItems: PropTypes.func.isRequired,
};

export default ShortCodes;
