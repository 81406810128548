/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { FiMessageSquare } from 'react-icons/fi';
import { RiChatSmile3Fill } from 'react-icons/ri';
// import * as momentTime from 'moment-timezone';

// Containers
import { SocketContext } from 'containers/context/socket';

// Assets
import { SiGooglecalendar } from 'react-icons/si';

// Utils
import { renderFlag, AlertNotification } from 'utils/helpers';
import { EventEmitter } from 'utils/emitter';
import { fireUpdates } from 'utils/eventHandle';

/**
 * @file view.js
 * @author Daniel Felipe Lucumi marin
 * @description Visitors table.
 */

const handleVisitors = (type, event, visitors, setVisitors) => {
  const response = fireUpdates(type, event, visitors);
  setVisitors(response);
};

function Visitors({ chatActions: { getVisitors }, user }) {
  const [visitors, setVisitors] = useState([]);
  const socket = useContext(SocketContext);
  const { t } = useTranslation();

  const getAllVisitors = () => getVisitors((type, data) => {
    if (type === 'success') {
      setVisitors(data);
    }
  });

  // useEffect(() => {
  //  getAllVisitors();
  // eslint-disable-next-line max-len
  //  EventEmitter.subscribe('visitor', event => { handleVisitors('visitor', event, visitors, setVisitors); getAllVisitors(); });
  // eslint-disable-next-line max-len
  //  EventEmitter.subscribe('leave_visitor', event => { handleVisitors('leave_visitor', event, visitors, setVisitors); getAllVisitors(); });
  //  EventEmitter.subscribe('remove_room', () => getAllVisitors());

  //   return () => {
  //     EventEmitter.unsubscribe('visitor');
  //     EventEmitter.unsubscribe('leave_visitor');
  //     EventEmitter.unsubscribe('remove_room');
  //   };
  // }, []);

  useEffect(() => {
    getAllVisitors();
    EventEmitter.subscribe('visitor', event => { visitors.push(event); getAllVisitors(); });
    EventEmitter.subscribe('leave_visitor', event => { handleVisitors('leave_visitor', event, visitors, setVisitors); getAllVisitors(); });
    EventEmitter.subscribe('remove_room', () => getAllVisitors());

    return () => {
      EventEmitter.unsubscribe('visitor');
      EventEmitter.unsubscribe('leave_visitor');
      EventEmitter.unsubscribe('remove_room');
    };
  }, []);

  const invitePerson = item => {
    socket.emit('SEND_INVITATION', item.id, user, response => {
      // eslint-disable-next-line no-console
      console.log('response', response, item);
      AlertNotification('success', t('alert-message-4'));
    });
  };

  const sendCalendly = (item, url) => {
    socket.emit('SEND_CALENDLY', item.id, url, response => {
      // eslint-disable-next-line no-console
      console.log('response', response);
      AlertNotification('success', t('alert-message-1'));
    });
  };

  const handleRenderVisitorName = visitor => {
    if (visitor.first_name && visitor.last_name) {
      const visitorName = `${visitor.first_name} ${visitor.last_name}`;
      return visitorName;
    }
    return '--';
  };

  return (
    <div className="flex justify-between flex-col flex-1 h-full w-full overflow-auto">
      <div className="flex h-full w-full p-5">
        <div className="w-full">
          <div className="h-full w-full">
            <table className="table-auto font-thin border overflow-x-auto w-full">
              <thead>
                <tr className="bg-white text-gray-700  text-sm font-thin">
                  <th className="py-3 h-full text-center border">{t('table-op-1')}</th>
                  <th className="py-3 h-full text-center border">{t('table-op-2')}</th>
                  <th className="py-3 h-full text-center border">{t('table-op-3')}</th>
                  <th className="py-3 h-full text-center border">{t('table-op-4')}</th>
                  <th className="py-3 h-full text-center border">{t('table-op-5')}</th>
                  <th className="py-3 h-full text-center border">{t('table-op-6')}</th>
                  <th className="py-3 h-full text-center border">{t('table-op-7')}</th>
                  {user.type !== 'Admin' && (
                  <th className="py-3 h-full text-center border" colSpan="2">
                    {t('table-op-8')}
                  </th>
                  )}
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-thin ">
                {visitors
                  && visitors.map(item => (
                    <tr
                      className="border-b border-gray-200 hover:bg-gray-100"
                      key={`item-${item.id}`}
                    >
                      <td className="py-3 h-full border" align="center">
                        <FiMessageSquare className="w-4 h-4" />
                      </td>
                      <td className="py-3 h-full border" align="center">
                        {/* {`${item.first_name} ${item.last_name}`} */}
                        {handleRenderVisitorName(item)}
                      </td>
                      <td className="py-3 h-full border" align="center">
                        {renderFlag(item.country_code)}
                      </td>
                      <td className="py-3 h-full border" align="center">
                        {item.city}
                      </td>
                      <td className="py-3 h-full border" align="center">
                        {item.device}
                      </td>
                      <td className="py-3 h-full border" align="center">
                        {moment(item.created_at).local().format('DD MMMM YYYY  HH:mm:ss')}
                      </td>
                      <td className="py-3 h-full border" align="center">
                        {item.user_name}
                      </td>
                      {user.type !== 'Admin' && (
                      <td className="flex-row flex py-3 h-full items-center justify-center divide-x-2 ">
                        <RiChatSmile3Fill
                          onClick={() => invitePerson(item)}
                          className="cursor-pointer w-12 h-6 text-green-500 px-2"
                          title="send invitation"
                        />
                        <SiGooglecalendar
                          className="cursor-pointer w-12 h-6 text-yellow-600 px-2"
                          onClick={() => sendCalendly(item, user.calendly_url)}
                          title="send calendar"
                        />
                      </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Proptypes definition.
 */
Visitors.propTypes = {
  globalActions: PropTypes.shape({
    setLoading: PropTypes.func,
  }).isRequired,
  chatActions: PropTypes.shape({
    getVisitors: PropTypes.func,
  }).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Visitors;
