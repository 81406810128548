/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import themeAnimated from '@amcharts/amcharts4/themes/animated';

/**
* @file index.js
* @author Daniela Perea
* @description Card component.
*/

function ChartScreens({
  statisticsData,
  selectedStatistic,
  start,
  end,
}) {
  const [data, setData] = useState('');

  const createChart = () => {
    am4core.useTheme(themeAnimated);
    const chart = am4core.create('chartdiv', am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.items = [{
      label: '...',
      menu: [
        { type: 'pdf', label: 'PDF' },
        { type: 'xlsx', label: 'EXCEL' },
        { label: 'Print', type: 'PRINT' },
      ],
    }];
    chart.exporting.dataFields = {
      Category: 'Counselor',
      Counselor: 'Counselor Module',
      Home: 'University Home Page',
      other: 'Other',
    };
    chart.exporting.filePrefix = `Shared_content_${start}_${end}`;
    chart.exporting.adapter.add('pdfmakeDocument', pdf => {
      // Add title to the beginning
      pdf.doc.content.unshift({
        text: `Shared Content from ${start} to ${end}`,
        margin: [30, 30],
        style: {
          fontSize: 18,
          bold: true,
        },
      });
      return pdf;
    });
    chart.exporting.getFormatOptions('pdf').addURL = false;
    chart.colors.step = 2;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;

    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = 'Category';
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;

    function createSeries(value, name) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = 'Category';
      series.name = name;

      const bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.text = '{valueY}';
      bullet.label.fill = am4core.color('#ffffff');

      return series;
    }

    chart.data = data;

    createSeries('Counselor', 'Counselor Module');
    createSeries('Home', 'Home Page');
    createSeries('Other', 'Other');
  };

  useEffect(() => {
    const tempData = [];
    statisticsData.forEach(element => {
      let counselor;
      let home;
      let other;
      element.screens.forEach(shared => {
        if (shared.type === 'counselor') {
          counselor = shared.count;
        } else if (shared.type === 'Home_page') {
          home = shared.count;
        } else {
          other = shared.count;
        }
      });
      const obj = {
        Category: element.name,
        Counselor: counselor,
        Home: home,
        Other: other,
      };
      tempData.push(obj);
    });
    setData(tempData);
  }, [statisticsData]);

  useEffect(() => {
    if (statisticsData.length > 0) {
      createChart();
    }
  }, [data]);

  useEffect(() => {
    am4core.disposeAllCharts();
  }, []);

  return (
    <>
      {statisticsData.length > 0 && (
        <div className="w-full h-full justify-center items-center p-8 overflow-auto min-w-max">
          <h1 className="text-gray-700 font-thin text-2xl mb-8">
            {selectedStatistic}
          </h1>
          <div
            id="chartdiv"
            style={{
              height: '80%',
              width: '100%',
            }}
          />
        </div>
      )}
    </>
  );
}

ChartScreens.propTypes = {
  statisticsData: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedStatistic: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

export default ChartScreens;
