/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';

// Utils
import { renderError } from 'utils/helpers';

// Scheme
import schema from './formConfig';

/**
 * @file index.jsx
 * @author Daniela perea
 * @description Login form component.
 */

function ProfileForm({ submitForm, userData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { t } = useTranslation();

  const onSubmit = data => submitForm(data);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <h1 className="font-ligth text-gray-800 mb-3 text-lg w-1/4">
        {t('profile-title')}
      </h1>
      <div className="w-full h-full px-10 pt-5">
        <div className="flex flex-col mb-4">
          <div className="flex flex-row">
            <h1 className="font-thin w-1/4 text-gray-600">
              {t('form-input-1')}
            </h1>
            <div className="w-full flex-col">
              <input
                className="w-full focus:outline-none focus:border-blue-300 px-2 border-gray-300 border focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-50 rounded-sm h-8 text-sm font-thin"
                type="text"
                name="first_name"
                id="first_name"
                ref={register}
                placeholder="Nombres"
                defaultValue={userData.first_name}
              />
              {errors.first_name && renderError(errors.first_name.message)}
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <div className="flex flex-row">
            <h1 className="font-thin w-1/4 text-gray-600">
              {t('form-input-2')}
            </h1>
            <div className="w-full flex-col">
              <input
                className="w-full focus:outline-none focus:border-blue-300 px-2 border-gray-300 border focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-50 rounded-sm h-8 text-sm font-thin"
                type="text"
                name="last_name"
                id="last_name"
                ref={register}
                defaultValue={userData.last_name}
              />
              {errors.last_name && renderError(errors.last_name.message)}
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <div className="flex flex-row">
            <h1 className="font-thin w-1/4 text-gray-600">
              {t('form-input-3')}
            </h1>
            <div className="w-full flex-col">
              <input
                className="w-full focus:outline-none focus:border-blue-300 px-2 border-gray-300 border focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-50 rounded-sm h-8 text-sm font-thin"
                type="text"
                name="email"
                id="email"
                ref={register}
                defaultValue={userData.email}
              />
              {errors.email && renderError(errors.email.message)}
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <div className="flex flex-row">
            <h1 className="font-thin w-1/4 text-gray-600">
              {t('form-input-4')}
            </h1>
            <div className="w-full flex-col">
              <input
                className="w-full focus:outline-none focus:border-blue-300 px-2 border-gray-300 border focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-50 rounded-sm h-8 text-sm font-thin"
                type="number"
                name="phone"
                id="phone"
                ref={register}
                defaultValue={userData.phone}
              />
              {errors.phone && renderError(errors.phone.message)}
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <div className="flex flex-row">
            <h1 className="font-thin w-1/4 text-gray-600">
              {t('form-input-5')}
            </h1>
            <div className="w-full flex-col">
              <input
                className="w-full focus:outline-none focus:border-blue-300 px-2 border-gray-300 border focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-50 rounded-sm h-8 text-sm font-thin"
                type="text"
                name="country_name"
                id="country_name"
                ref={register}
                defaultValue={userData.country_name}
              />
              {errors.country_name && renderError(errors.country_name.message)}
            </div>
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <div className="flex flex-row">
            <h1 className="font-thin w-1/4 text-gray-600">
              {t('form-input-6')}
            </h1>
            <div className="w-full flex-col">
              <input
                className="w-full focus:outline-none focus:border-blue-300 px-2 border-gray-300 border focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-50 rounded-sm h-8 text-sm font-thin"
                type="number"
                name="country_code"
                id="country_code"
                ref={register}
                defaultValue={userData.country_code}
              />
              {errors.country_code && renderError(errors.country_code.message)}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end pt-3">
          <button
            type="submit"
            className="w-24 h-8 text-sm font-thin bg-blue-400 hover:bg-blue-500 text-white rounded-sm focus:outline-none focus:border-blue-300"
          >
            {t('from-btn-1')}
          </button>
        </div>
      </div>
    </form>
  );
}

/**
 * Proptypes definition.
 */
ProfileForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProfileForm;
