/* eslint-disable max-len */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import PopoverTopbar from 'components/popover';

// Assets
import Logo from 'assets/images/Logo-LAE.png';
import { Link } from 'react-router-dom';
import { FiPower } from 'react-icons/fi';

/**
 * @file index.js
 * @author Juan David Rojas
 * @description Header
 */

const Header = ({
  title, logoutFn, handleStatus, status, setStatus, user,
}) => {
  useEffect(() => {
    setTimeout(() => {
      handleStatus(status);
    }, 3000);
  }, []);
  return (
    <>
      <nav
        aria-label="top bar"
        className="flex-none flex justify-between h-20 bg-black"
      >
        <ul className="flex">
          <li className="group relative px-2">
            <div className="w-7/12 mt-4 ml-2">
              <Link to={`${user.type === 'Admin' ? '/statistics' : '/home'}`}>
                <img src={Logo} alt="Torrens" className="w-76" />
              </Link>
            </div>
          </li>
        </ul>
        <ul className="px-8 flex items-center">
          <li className="h-10 w-10 ml-3 flex justify-content items-center">
            <h1 className="text-gray-600 font-thin">
              {status ? 'Online' : 'Offline'}
            </h1>
          </li>
          <li className="h-10 w-10 ml-3">
            <button
              type="button"
              onClick={() => {
                handleStatus(!status);
                setStatus(!status);
              }}
              className="flex justify-content items-center rounded-full hover:bg-gray-100 p-3 focus:outline-none"
            >
              <FiPower
                className={`w-4 h-4 ${
                  status ? 'text-green-500' : 'text-red-500'
                }`}
              />
            </button>
          </li>
          <li className="h-10 w-10 ml-3">
            <PopoverTopbar logoutFn={logoutFn} user={user} />
          </li>
        </ul>
      </nav>
      <header
        aria-label="page caption"
        className="flex-none flex h-24 bg-white border-t px-4 items-center border-l"
      >
        <h1
          id="page-caption"
          className="font-ligth text-2xl text-gray-800 tracking-widest"
        >
          {title}
        </h1>
      </header>
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  logoutFn: PropTypes.func.isRequired,
  handleStatus: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  setStatus: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Header;
