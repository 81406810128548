/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Assets
import Logo from 'assets/images/logo.png';

// Icons
import { HiOutlineMenuAlt3, HiOutlineMenu } from 'react-icons/hi';
import {
  FiHome,
  FiInbox,
  FiUser,
  FiBarChart,
  FiSettings,
} from 'react-icons/fi';

/**
 * @file index.js
 * @author Juan David Rojas
 * @description Navigation bar
 */

const NavigationBar = ({ setMenuOption, selected, type }) => {
  const [isShown, setIsShown] = useState(false);

  const sidebarOptions = [
    {
      name: 'Home',
      icon: <FiHome />,
      route: '/home',
    },
    {
      name: 'Visitors',
      icon: <FiInbox />,
      route: '/visitors',
    },
    {
      name: 'Statistics',
      icon: <FiBarChart />,
      route: '/statistics',
    },
    {
      name: 'Profile',
      icon: <FiUser />,
      route: '/profile',
    },
    {
      name: 'Settings',
      icon: <FiSettings />,
      route: '/settings',
    },
  ];

  const { t } = useTranslation();

  const handleToggle = () => {
    setIsShown(!isShown);
  };

  const handleFilterByTypeUser = option => {
    switch (type) {
      case 'Admin': return option.name === 'Home' || option.name === 'Statistics';
      case 'Counselor': return option;
      default: return true;
    }
  };

  return (
    <nav className="flex-none flex flex-col items-center text-center bg-black text-gray-400 z-10">
      <button
        type="button"
        className="h-20 flex items-center w-full text-white-800 focus:outline-none"
        onClick={() => handleToggle()}
      >
        <i className={`mx-auto ${isShown ? 'flex flex-row justify-between w-full' : ''}`}>
          {isShown ? (
            <>
              <h1 className={`font-medium text-sm tracking-widest not-italic px-6 ${isShown ? 'flex' : 'hidden'}`}>
                {' '}
                { selected && t(`sidebar-op-${selected}`)}
                {' '}
              </h1>
              <HiOutlineMenuAlt3 className="mx-6" />
            </>
          ) : (
            <HiOutlineMenu />
          )}
        </i>
      </button>
      <ul className="border-t">
        {sidebarOptions
          .filter(option => handleFilterByTypeUser(option))
          .map((option, index) => (
            <li key={index}>
              <NavLink to={option.route} activeClassName="text-blue-500" className="h-16 px-3 flex items-center text-gray-300 hover:text-gray-600 w-full" title={option.name}>
                <i className="flex items-center rounded-full hover:bg-gray-100 p-3">
                  {option.icon}
                </i>
                <button type="button" className="bg-transparent border-transparent border-none outline-none" onClick={() => setMenuOption(index + 1)}>
                  <h1 className={`font-thin text-sm pr-20  ${isShown ? 'flex' : 'hidden'}`}>
                    {t(`sidebar-op-${index + 1}`)}
                  </h1>
                </button>
              </NavLink>
            </li>
          ))}
      </ul>
    </nav>
  );
};

NavigationBar.propTypes = {
  setMenuOption: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default NavigationBar;
