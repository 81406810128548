/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

// eslint-disable-next-line import/prefer-default-export
export const GET_VISITORS_INFO = 'PROFILE/GET_VISITORS_INFO';
