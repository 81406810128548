/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React from 'react';

function noFound() {
  return (
    <div>No found</div>
  );
}

export default noFound;
