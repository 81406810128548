/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University
 * All Rights Reserved.
 */

import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { SET_USER } from '../app/constants';
import { AUTH_LOGOUT, USER_OFFLINE } from './constants';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Redux store.
 */

const defaultUser = {
  id: null,
  name: null,
  email: null,
  role: null,
  online: true,
};

const initialState = {
  isAuthenticated: false,
  token: null,
  user: defaultUser,
  status: false,
};

const authLogin = (state, payload) => {
  const newState = {
    isAuthenticated: true,
    user: payload.user,
    status: true,
  };

  const stateObj = { ...state, ...newState };
  window.localStorage.setItem('token', payload.token);
  window.localStorage.setItem('userInfo', payload.user.id);
  return stateObj;
};

const setUserStatus = (state, payload) => {
  const newState = {
    isAuthenticated: true,
    user: payload,
    status: payload.online,
  };
  window.localStorage.setItem('userInfo', payload.id);
  const stateObj = { ...state, ...newState };
  return stateObj;
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('persist:auth');

  return initialState;
};

const Auth = persistReducer(
  { storage, key: 'auth', whitelist: ['user', 'isAuthenticated', 'status'] },
  (state = initialState, { type, payload = null }) => {
    switch (type) {
      case SET_USER:
        return authLogin(state, payload);
      case AUTH_LOGOUT:
        return logout(state);
      case USER_OFFLINE:
        return setUserStatus(state, payload);
      default:
        return state;
    }
  },
);

export default Auth;
