/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

import { SET_ONLINE_ROOMS } from './constants';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Redux store.
 */

const initialState = [];

const Rooms = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case SET_ONLINE_ROOMS:
      return payload;
    default:
      return state;
  }
};

export default Rooms;
