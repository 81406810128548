/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

/**
* @file index.js
* @author Daniela Perea
* @description Card component.
*/

function LeadsTable({
  statisticsData,
  selectedStatistic,
  start,
  end,
}) {
  useEffect(() => {
  }, [statisticsData]);

  return (
    <>
      {statisticsData && statisticsData.length > 0 && (
        <div className="w-full h-full justify-center items-center p-8 overflow-auto min-w-max">
          <div className="flex flex-row justify-between">
            <h1 className="text-gray-700 font-thin text-2xl mb-8">{selectedStatistic}</h1>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="bg-blue-500 hover:bg-blue-700 text-white rounded-md py-1 px-3 h-8 focus:outline-none"
              table="mytable"
              filename={`All_Leads_${start}_${end}`}
              sheet="tablexls"
              buttonText="Download as XLS"
            />
          </div>
          <div className="rounded-r-sm">
            <table id="mytable" className="table-auto font-thin border overflow-x-auto xl:w-full lg:w-min">
              <thead>
                <tr className="bg-white text-gray-700  text-sm font-thin">
                  <th className="py-3 md:px-2 text-center border">Name</th>
                  <th className="py-3 md:px-2 text-center border">Country</th>
                  <th className="py-3 md:px-2 text-center border">City</th>
                  <th className="py-3 md:px-2 text-center border">Nationalitty</th>
                  <th className="py-3 md:px-2 text-center border">Phone</th>
                  <th className="py-3 md:px-2 text-center border">email</th>
                  <th className="py-3 md:px-2 text-center border">Assigned to</th>
                  <th className="py-3 md:px-2 text-center border">Visit start</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-thin">
                {statisticsData
                  && statisticsData.map(item => (
                    <tr
                      className="border-b border-gray-200 hover:bg-gray-100"
                      key={item.email}
                    >
                      <td className="py-3 md:px-2 border" align="center">
                        {`${item.first_name} ${item.last_name}`}
                      </td>
                      <td className="py-3 md:px-2 border" align="center">
                        {item.country}
                      </td>
                      <td className="py-3 md:px-2 border" align="center">
                        {item.zip}
                      </td>
                      <td className="py-3 md:px-2 border" align="center">
                        {item.country}
                      </td>
                      <td className="py-3 md:px-2 border" align="center">
                        {item.phone}
                      </td>
                      <td className="py-3 md:px-2 border" align="center">
                        {item.email}
                      </td>
                      <td className="py-3 md:px-2 border" align="center">
                        {item.user_name}
                      </td>
                      <td className="py-3 md:px-2 border" align="center">
                        {moment(item.start_chat).local().format('DD MMMM YYYY  HH:mm:ss')}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

LeadsTable.propTypes = {
  statisticsData: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedStatistic: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

export default LeadsTable;
