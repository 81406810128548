/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';

// Components
import Sidebar from 'components/sidebar';
import Header from 'components/header';

// Context
import { SocketContext } from 'containers/context/socket';

// Utils
import { EventEmitter } from 'utils/emitter';
// import { getNotificationsPermissions } from 'utils/helpers';
import { AlertNotification, getNotificationsPermissions } from 'utils/helpers';

// Assets
import NotificationSound from 'assets/audio/notification.wav';

/**
 * @file AppLayout.js
 * @author Daniel Felipe Lucumi Marin
 * @description App Layout
 */

const AppLayout = ({
  children,
  title,
  globalActions: { sendNotification, setMenuOption },
  authActions: { authLogout, setUserStatus },
  user,
  isAuthenticated,
  sidebarOp,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [isFocused, setIsFocused] = useState(true);
  const socket = useContext(SocketContext);
  const [status, setStatus] = useState(user.online);
  const notificationSound = new Audio(NotificationSound);

  useEffect(() => {
    window.addEventListener('focus', () => {
      setIsFocused(true);
    });
    window.addEventListener('blur', () => {
      setIsFocused(false);
    });
  });

  useEffect(() => {
    socket.on('connect', () => {
      socket.emit('JOIN_ROOM', () => {
      });
    });

    socket.on('disconnect', () => {
    });

    socket.on('USER_IN_WEB_PAGE', data => {
      EventEmitter.dispatch('visitor', data);
      EventEmitter.dispatch('visitor_chat', data);
    });

    socket.on('VISITOR_LEAVE', data => {
      EventEmitter.dispatch('leave_visitor', data);
      EventEmitter.dispatch('leave_visitor_chat', data);
    });

    socket.on('SEND_SURVEY', data => {
      EventEmitter.dispatch('is_chating_change', data);
      EventEmitter.dispatch('is_chating_change_chat', data);
    });

    socket.on('CREATED_ROOM', data => {
      EventEmitter.dispatch('add_room', data);
      EventEmitter.dispatch('add_room_chat', data);
    });

    socket.on('ROOM_LEAVE', data => {
      EventEmitter.dispatch('remove_room', data);
      EventEmitter.dispatch('remove_room_chat', data);
    });

    socket.on('RENDER_MESSAGE', data => {
      EventEmitter.dispatch('add_message', data);
      if (data.visitor_id) {
        sendNotification(data);
        const notificationStatus = getNotificationsPermissions();
        if (data.visitor_id) {
          AlertNotification('info', 'Do you have a new message!');
          notificationSound.play();
        } else {
          AlertNotification('info', 'New message!');
          notificationSound.play();
        }
        if (notificationStatus) {
          // notificationSound.play();
          return new Notification('New message');
        }
      }
      return true;
    });

    socket.on('SEND_IS_CHATING', data => {
      EventEmitter.dispatch('get_is_chating', data);
      EventEmitter.dispatch('get_is_chating_chat', data);
    });

    return () => {
      socket.off('disconnect');
      socket.off('connect');
      socket.off('USER_IN_WEB_PAGE');
      socket.off('VISITOR_LEAVE');
      socket.off('ROOM_LEAVE');
      socket.off('RENDER_MESSAGE');
      socket.off('IS_CHATING');
    };
  }, []);

  const handleStatus = state => {
    if (isAuthenticated) {
      setUserStatus(user.id, state, type => {
        if (type === 'success') {
          socket.emit('onUserDisconnect', user.id);
        }
      });
    }
  };

  return (
    <div className="h-full w-full flex overflow-hidden antialiased text-gray-800 bg-white">
      <Sidebar setMenuOption={setMenuOption} selected={sidebarOp} type={user.type} />
      <div className="flex-1 flex flex-col">
        <Header
          user={user}
          title={title}
          logoutFn={authLogout}
          handleStatus={handleStatus}
          setStatus={setStatus}
          status={status}
        />
        <main className="flex-1 w-max-full flex min-h-0 border-t border-l">
          {children}
        </main>
      </div>
      <ToastContainer />
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  title: PropTypes.string.isRequired,
  globalActions: PropTypes.shape({
    sendNotification: PropTypes.func,
    setMenuOption: PropTypes.func,
  }).isRequired,
  authActions: PropTypes.shape({
    authLogout: PropTypes.func,
    setUserStatus: PropTypes.func,
  }).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  sidebarOp: PropTypes.number.isRequired,
};

export default AppLayout;
