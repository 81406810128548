/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import {
  GET_VISITORS,
  GET_SHORTCODES,
  GET_LANGUAGES,
  SAVE_SHORTCODE,
  GET_WP_STATUS,
  GET_WP_QR,
  SEND_ADVISER_COPY,
  GET_ALL_SHORTCODES,
  DELETE_SHORTCODE,
  GET_CM_URL,
} from './constants';

/**
 * @file actions.js
 * @author Daniel Felipe Lucumi Marin
 * @description destinations actions.
 */

export const getVisitors = cb => ({
  type: GET_VISITORS,
  cb,
});

export const getLanguages = cb => ({
  type: GET_LANGUAGES,
  cb,
});

export const getShortcodes = (page, code, cb) => ({
  type: GET_SHORTCODES,
  payload: { page, code },
  cb,
});

export const getAllShortcodes = (page, cb) => ({
  type: GET_ALL_SHORTCODES,
  payload: { page },
  cb,
});

export const createShortCode = (data, cb) => ({
  type: SAVE_SHORTCODE,
  payload: data,
  cb,
});

export const checkWpAuth = cb => ({
  type: GET_WP_STATUS,
  cb,
});

export const getWpQr = cb => ({
  type: GET_WP_QR,
  cb,
});

export const sendAdviserCopy = (visitor, user, room, url, cb) => ({
  type: SEND_ADVISER_COPY,
  payload: {
    visitor, user, room, url,
  },
  cb,
});

export const deleteShortcode = (id, cb) => ({
  type: DELETE_SHORTCODE,
  payload: id,
  cb,
});

export const getCmUrl = (email, cb) => ({
  type: GET_CM_URL,
  payload: email,
  cb,
});
