/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useState, useEffect, useRef } from 'react';
import S3 from 'react-aws-s3';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import swal from 'sweetalert';

// Containers
import AppLayout from 'containers/layout';

// Components
import ProfileForm from 'components/forms/profile';
import ChangePasswordForm from 'components/forms/changePassword';

// Assets
import defaultImage from 'assets/images/default-user.png';

// Icons
import { FiPlus } from 'react-icons/fi';
import { AWS_CONFIG } from 'utils/enviroment';

/**
* @file view.js
* @author Daniel Felipe Lucumi Marin
* @description Home page.
*/

function profile({ profileActions: { getUsersInfo, saveUsersInfo, changeUserPassword }, user }) {
  const [qr, setQr] = useState(false);
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [hideOldPass, setHideOldPass] = useState(true);
  const [hideNewPass, setHideNewPass] = useState(true);
  const hiddenFileInput = useRef(null);
  const { t } = useTranslation();

  const getUsers = () => {
    getUsersInfo(user.id, (type, response) => {
      if (type === 'success') {
        setUserData(response);
      }
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const uploadFile = e => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const newFileName = e.target.files[0].name;
      const config = AWS_CONFIG;
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, newFileName)
        .then(data => {
          if (data.status === 204) {
            const sendFile = {
              picture: data.location,
            };
            saveUsersInfo(user.id, sendFile, (type, response) => {
              if (type === 'success') {
                getUsers();
              }
            });
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('error', error);
        });
    }
  };

  const handleSubmit = data => {
    saveUsersInfo(user.id, data, (type, response) => {
      if (type === 'success') {
        swal(t('update-success-title-message'), t('update-success-message'), 'success');
      } else {
        swal(t('update-error-title-message'), t('update-error-message'), 'error');
      }
    });
  };

  const handlePasswordchange = data => {
    changeUserPassword(user.id, data, (type, response) => {
      if (type === 'success') {
        swal(t('update-success-title-message'), t('update-success-message'), 'success');
      } else {
        swal(t('update-error-title-message'), t('update-error-credentials-message'), 'error');
      }
    });
  };

  const handleClickFile = e => {
    hiddenFileInput.current.click();
  };

  return (
    <AppLayout title={t('topbar-op-1')}>
      <div className="w-full flex flex-col p-8 overflow-auto">
        <section className="flex flex-row mb-5">
          <section className="flex flex-col max-w-lg flex-auto bg-white min-h-0">
            <div className="flex flex-row p-4 w-full bg-white overflow-auto items-center border rounded-sm">
              <button
                type="button"
                onClick={e => handleClickFile(e)}
                className="relative focus:outline-none"
              >
                <img src={!userData.picture ? defaultImage : userData.picture} alt="logo-user" className="rounded-full h-24 w-24" />
                <div className="absolute bottom-0 right-0 flex items-center rounded-full bg-blue-400 hover:bg-blue-500 p-2 text-white">
                  <FiPlus className="w-3 h-3" />
                </div>
              </button>
              <input
                className="hidden"
                type="file"
                ref={hiddenFileInput}
                onChange={e => uploadFile(e)}
              />
              <div className="flex flex-col mx-8">
                <h1 className="font-thin text-xl text-gray-700">
                  {`${userData.first_name} ${userData.last_name}`}
                </h1>
                <h1 className="font-thin text-gray-500 text-sm mt-1">
                  {userData.email}
                </h1>
                <h1 className="font-thin text-gray-500 text-sm">
                  {userData.phone}
                </h1>
              </div>
            </div>
          </section>
          <section className="flex min-h-0 pl-8 flex-col flex-auto bg-white">
            <div className="flex flex-col p-4  mb-2 bg-white border rounded-sm">
              <ProfileForm submitForm={handleSubmit} userData={userData} />
            </div>
          </section>
        </section>
        <section className="flex min-h-0 flex-col flex-auto bg-white">
          <div className="flex flex-row flex-auto bg-white rounded-sm">
            <ChangePasswordForm
              submitForm={handlePasswordchange}
              oldPasswordActions={{ hideOldPass, setHideOldPass }}
              newPasswordActions={{ hideNewPass, setHideNewPass }}
            />
          </div>
        </section>
      </div>
    </AppLayout>
  );
}

/**
 * Proptypes definition.
 */
profile.propTypes = {
  profileActions: PropTypes.shape({
    getUsersInfo: PropTypes.func,
    saveUsersInfo: PropTypes.func,
    changeUserPassword: PropTypes.func,
  }).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  // loading: PropTypes.bool.isRequired,
};

export default profile;
