/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ChartRates from 'components/chartRates';
import ChartVideocalls from 'components/chartVideocalls';
import ChartNationalities from 'components/chartNationalities';
import ChartLevel from 'components/chartLevel';
import StatisticsTable from 'components/statisticsTable/statisticsTable';
import TimeTable from 'components/timeTable';
import ChartScreens from 'components/chartScreens';
import ChartCities from 'components/chartCities';
import moment from 'moment';
import LeadsTable from 'components/allLeadsTable';

// Containers
import AppLayout from 'containers/layout';
import ChatBox from 'containers/chat';

// Components
import Card from 'components/card';

/**
* @file view.js
* @author Daniela Perea.
* @description Home page.
*/

function Statistics({
  statisticActions: { getGeneralStatistics, getStatistics, createLeadsStatistic },
  user,
}) {
  const [statisticsData, setStatisticsData] = useState([]);
  const [generalStatisticsData, setGeneralStatisticsData] = useState([]);
  const [leadsStatisticsData, setLeadsStatisticsData] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
  const [selectedStatistic, setSelectedStatistic] = React.useState('General statistics table');

  const handleThisWeek = date => {
    setSelectedStartDate(moment().startOf('isoweek').toDate());
    setSelectedEndDate(moment().endOf('isoweek').toDate());
  };
  const options = ['General statistics table', 'All leads', 'Consulting time', 'Rates', 'Chats and Videocalls', 'Shared Contents', 'Top 10 Nationalities', 'Top 10 Cities', 'High potential visitors'];

  const useStyles = makeStyles(() => ({
    button: {
      outline: 'none',
      fontSize: '0.5em',
      margin: '0px 15px',
      color: '#E5E7EB',
    },
  }));

  const classes = useStyles();

  const renderView = () => {
    switch (selectedStatistic) {
      case 'General statistics table':
        return (
          <StatisticsTable
            statisticsData={statisticsData}
            selectedStatistic={selectedStatistic}
            start={moment(selectedStartDate).format('YYYY-MM-DD')}
            end={moment(selectedEndDate).format('YYYY-MM-DD')}
          />
        );
      case 'Consulting time':
        return (
          <TimeTable
            statisticsData={statisticsData}
            selectedStatistic={selectedStatistic}
            start={moment(selectedStartDate).format('YYYY-MM-DD')}
            end={moment(selectedEndDate).format('YYYY-MM-DD')}
          />
        );
      case 'Rates':
        return (
          <ChartRates
            statisticsData={statisticsData}
            selectedStatistic={selectedStatistic}
            start={moment(selectedStartDate).format('YYYY-MM-DD')}
            end={moment(selectedEndDate).format('YYYY-MM-DD')}
          />
        );
      case 'Chats and Videocalls':
        return (
          <ChartVideocalls
            statisticsData={statisticsData}
            selectedStatistic={selectedStatistic}
            start={moment(selectedStartDate).format('YYYY-MM-DD')}
            end={moment(selectedEndDate).format('YYYY-MM-DD')}
          />
        );
      case 'Top 10 Nationalities':
        return (
          <ChartNationalities
            generalStatisticsData={generalStatisticsData}
            selectedStatistic={selectedStatistic}
            start={moment(selectedStartDate).format('YYYY-MM-DD')}
            end={moment(selectedEndDate).format('YYYY-MM-DD')}
          />
        );
      case 'Top 10 Cities':
        return (
          <ChartCities
            generalStatisticsData={generalStatisticsData}
            selectedStatistic={selectedStatistic}
            start={moment(selectedStartDate).format('YYYY-MM-DD')}
            end={moment(selectedEndDate).format('YYYY-MM-DD')}
          />
        );
      case 'High potential visitors':
        return (
          <ChartLevel
            generalStatisticsData={generalStatisticsData}
            selectedStatistic={selectedStatistic}
            start={moment(selectedStartDate).format('YYYY-MM-DD')}
            end={moment(selectedEndDate).format('YYYY-MM-DD')}
          />
        );
      case 'Shared Contents':
        return (
          <ChartScreens
            statisticsData={statisticsData}
            selectedStatistic={selectedStatistic}
            start={moment(selectedStartDate).format('YYYY-MM-DD')}
            end={moment(selectedEndDate).format('YYYY-MM-DD')}
          />
        );
      case 'All leads':
        return (
          <LeadsTable
            statisticsData={leadsStatisticsData}
            selectedStatistic={selectedStatistic}
            start={moment(selectedStartDate).format('YYYY-MM-DD')}
            end={moment(selectedEndDate).format('YYYY-MM-DD')}
          />
        );
      default:
        return (
          <ChartVideocalls
            statisticsData={statisticsData}
            selectedStatistic={selectedStatistic}
            start={moment(selectedStartDate).format('YYYY-MM-DD')}
            end={moment(selectedEndDate).format('YYYY-MM-DD')}
          />
        );
    }
  };

  const getStatisticsInfo = () => {
    getStatistics(moment(selectedStartDate).format('YYYY-MM-DD HH:mm'), moment(selectedEndDate).format('YYYY-MM-DD HH:mm'), (type, response) => {
      if (type === 'success') {
        setStatisticsData(response.statistics);
      }
    });

    getGeneralStatistics(moment(selectedStartDate).format('YYYY-MM-DD HH:mm'), moment(selectedEndDate).format('YYYY-MM-DD HH:mm'), (type, response) => {
      if (type === 'success') {
        setGeneralStatisticsData(response.statistics);
      }
    });

    createLeadsStatistic(moment(selectedStartDate).format('YYYY-MM-DD HH:mm'), moment(selectedEndDate).format('YYYY-MM-DD HH:mm'), (type, response) => {
      if (type === 'success') {
        setLeadsStatisticsData(response);
      }
    });
  };

  useEffect(() => {
    getStatisticsInfo();
    handleThisWeek();
  }, []);

  useEffect(() => {
    getStatisticsInfo();
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    renderView();
  }, [statisticsData]);

  return (
    <AppLayout title={t('statistics-title')}>
      <div className="border m-8 w-full rounded-sm flex-row flex">
        <section className="flex flex-col w-full max-w-sm flex-none bg-white min-h-0 h-full  rounded-l-sm border-r">
          <div className="h-16 border-b items-center flex">
            <h1 className="text-gray-700 font-thin m-4">
              {t('statistics-subtitle')}
            </h1>
          </div>
          <div className="w-full flex-1 p-4 overflow-auto">
            {options && options.map((item, index) => (
              <Card
                key={index}
                itemStatistics={item}
                setSelectedStatistic={setSelectedStatistic}
                selectedStatistic={selectedStatistic}
              />
            ))}
          </div>
        </section>
        <section className="flex min-h-0 flex-col flex-auto ">
          <div className="flex justify-between flex-col">
            <div className="flex justify-end items-center border-b border-gray-200 h-16 px-5 z-10">
              <MuiPickersUtilsProvider utils={DateFnsUtils} className="flex flex-row w-full focus:outline-none">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="YYYY-MM-DD"
                  margin="normal"
                  id="date-pickere"
                  label="Start date"
                  className={classes.button}
                  focused={false}
                  value={selectedStartDate}
                  onChange={date => setSelectedStartDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM-DD-YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  label="End date"
                  className={classes.button}
                  value={selectedEndDate}
                  onChange={date => setSelectedEndDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white rounded-md py-1 px-3"
                onClick={() => handleThisWeek()}
              >
                This week
              </button>
            </div>
          </div>
          {renderView()}
        </section>
      </div>
    </AppLayout>
  );
}

/**
 * Proptypes definition.
 */
Statistics.propTypes = {
  statisticActions: PropTypes.shape({
    getStatistics: PropTypes.func,
    getGeneralStatistics: PropTypes.func,
    createLeadsStatistic: PropTypes.func,
  }).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Statistics;
