/**
 * This source code is the confidential, proprietary information of
 * Torrens University, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens University.
 *
 * Torrens University.
 * All Rights Reserved.
 */

/**
 * @file constants.js
 * @author Daniel Felipe Lucumi Marin.
 * @description Global Application constants.
 */

// Generic actions
export const SET_LOADING = 'App/SET_LOADING';
export const SET_RESPONSE = 'App/SET_RESPONSE';
export const SET_USER = 'App/SET_USER';
export const SET_MENU_OPTION = 'App/SET_MENU_OPTION';
export const SEND_NOTIFICATION = 'App/SEND_NOTIFICATION';
export const RESET_NOTIFICATION = 'App/RESET_NOTIFICATION';
export const SET_VISITOR = 'App/SET_VISITOR';
export const SET_REPLAY_MESSAGE = 'App/SET_REPLAY_MESSAGE';
