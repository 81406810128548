/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import {
  GET_MY_ROOMS,
  CLOSE_ROOM,
  SET_ONLINE_ROOMS,
  HIDE_ROOM,
  SELECTED_ROOM,
} from './constants';

/**
 * @file actions.js
 * @author Daniel Felipe Lucumi Marin
 * @description rooms actions.
 */

export const setOnlineRooms = rooms => ({
  type: SET_ONLINE_ROOMS,
  payload: rooms,
});

export const getMyRooms = cb => ({
  type: GET_MY_ROOMS,
  cb,
});

export const closeRoom = (data, cb) => ({
  type: CLOSE_ROOM,
  payload: data,
  cb,
});

export const hideRoom = (data, cb) => ({
  type: HIDE_ROOM,
  payload: data,
  cb,
});

export const getSelectedRooms = (id, cb) => ({
  type: SELECTED_ROOM,
  payload: id,
  cb,
});
