/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import Login from 'pages/login';
import Home from 'pages/home';
import Setting from 'pages/setting';
import Profile from 'pages/Profile';
import Visitors from 'pages/visitors';
import Statistics from 'pages/statistics';
/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Redux store.
 */

const routes = [
  {
    path: '/',
    exact: true,
    auth: false,
    component: Login,
  },
  {
    path: '/home',
    exact: true,
    auth: true,
    component: Home,
  },
  {
    path: '/home/chat/:id',
    exact: true,
    auth: true,
    component: Home,
  },
  {
    path: '/settings',
    exact: true,
    auth: true,
    component: Setting,
  },
  {
    path: '/profile',
    exact: true,
    auth: true,
    component: Profile,
  },
  {
    path: '/visitors',
    exact: true,
    auth: true,
    component: Visitors,
  },
  {
    path: '/visitors/chat/:id',
    exact: true,
    auth: true,
    component: Visitors,
  },
  {
    path: '/statistics',
    exact: true,
    auth: true,
    component: Statistics,
  },
];

export default routes;
