/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';

// Redux
import * as globalActions from 'redux/stores/app/actions';
import * as authActions from 'redux/stores/auth/actions';
import * as chatActions from 'redux/stores/chat/actions';

// Component
import View from './view';

/**
 * @file index.js
 * @author Daniel Felipe Lucumi Marin
 * @description Visitors view.
 */

/**
 * Map state to props
 * @returns { object }
 */
export const mapStateToProps = state => ({
  loading: state.Global.loading,
  isAuthenticated: state.Auth.isAuthenticated,
  user: state.Auth.user,
});

/**
 * Map dispatch to props
 * @param dispatch - Run the  process to execute in the action
 * @returns { object }
 */
export const mapDispatchToProps = dispatch => ({
  globalActions: bindActionCreators(globalActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch),
  chatActions: bindActionCreators(chatActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(View));
