/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/**
 * This source code is the confidential, proprietary information of
 * Torrens, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with Torrens.
 *
 * Torrens
 * All Rights Reserved.
 */

import React, {
  useState, useRef, useContext, useEffect,
} from 'react';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import ReactGiphySearchbox from 'react-giphy-searchbox';
import PropTypes from 'prop-types';
import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

// Containers
import { SocketContext } from 'containers/context/socket';

// Utils
import {
  uploadFileIntoChat,
  handleLoadShortcut,
  AlertNotification,
  scrollToBottom,
} from 'utils/helpers';
import { GIPHY_API_KEY } from 'utils/enviroment';

// Assets
import {
  FiHeart, FiPaperclip, FiSend, FiSmile,
} from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { RiSurveyLine } from 'react-icons/ri';

// Components
import Audio from 'components/audio';
import handleMessage from 'containers/chat/handleMessage';

/**
 * @file view.js
 * @author Brayan David Coy
 * @description Compose message component.
 */

export default function ComposeMessage({
  replayMessage,
  optionMessage,
  messageActions,
  globalActions,
  user,
  id,
  setOptionMessage,
  messages,
  visitorLang,
  isDisabled,
  getShortcodes,
  handleRenderMessageContent,
  visitorStatus,
  visitorId,
  visitorOnline,
  visitorChating,
  status,
}) {
  const [isRecording, setIsRecording] = useState(false);
  const [showGifs, setShowGifs] = useState(false);
  const [value, setValue] = useState('');
  const [audio, setAudio] = useState({});
  const [shortCode, setShortCode] = useState({});
  const [pageShortcode, setPageShortcode] = useState(1);
  const [shortcutView, setShortcutView] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const fileInput = useRef(null);
  const socket = useContext(SocketContext);
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const prospection1 = 'https://recruitmentchatvc.lae-edu.com/';
  const prospection2 = 'https://recruitmentchatvc.lae-edu.com/2';
  const prospection3 = 'https://recruitmentchatvc.lae-edu.com/3';
  const prospection4 = 'https://recruitmentchatvc.lae-edu.com/4';
  const prospection5 = 'https://recruitmentchatvc.lae-edu.com/5';
  const prospection6 = 'https://recruitmentchatvc.lae-edu.com/6';
  const prospection7 = 'https://recruitmentchatvc.lae-edu.com/7';
  const confirmEmail = 'https://recruitmentchatvc.lae-edu.com/confirm';
  const [greet, setGreet] = useState(false);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  // const setModalIsOpenToFalse = () => {
  //   setModalIsOpen(false);
  // };

  const initialGreeting = () => {
    const greeting = '¡Hola! Bienvenid@ a la asesoría virtual de LAE Educación Internacional. ¿Cómo puedo ayudarte el día de hoy?';
    let messageResponse = {};
    messageResponse = handleMessage('Text', greeting, id, user.id);
    if (visitorOnline) {
      messageActions.sendMessage(messageResponse, visitorLang, type => {
        if (type === 'success') {
          setValue('');
          globalActions.setReplayMessage({});
          setOptionMessage(false);
          localStorage.setItem(visitorId, visitorId);
          setAudio({});
          scrollToBottom();
          setGreet(true);
          messageActions.renderMessages([...messages, messageResponse]);
        }
      });
    }
  };

  useEffect(() => {
    const saludo = localStorage.getItem(visitorId);
    if (saludo !== visitorId) {
      initialGreeting();
    }
  }, visitorId);

  const sendMessage = async event => {
    let messageResponse = {};
    if (
      event.key === 'Enter'
      || (event.type === 'click' && value.trim() !== '')
    ) {
      messageResponse = handleMessage('Text', value, id, user.id);
    }
    if (event.type === 'click' && Object.keys(audio).length > 0) {
      messageResponse = handleMessage('Audio', audio.url, id, user.id);
    }
    if (Object.keys(messageResponse).length > 0 && messageResponse.content.trim() !== '') {
      if (Object.keys(replayMessage).length > 0) {
        messageResponse.parent_id = replayMessage.id;
      }
      messageActions.sendMessage(messageResponse, visitorLang, type => {
        if (type === 'success') {
          setValue('');
          globalActions.setReplayMessage({});
          setOptionMessage(false);
          setAudio({});
          scrollToBottom();
          messageActions.renderMessages([...messages, messageResponse]);
        }
      });
    }
  };

  const uplodImageIntoChat = url => {
    const messageResponse = handleMessage('Image', url, id, user.id);
    if (Object.keys(messageResponse).length > 0) {
      if (Object.keys(replayMessage).length > 0) {
        messageResponse.parent_id = replayMessage.id;
      }
      messageActions.sendMessage(messageResponse, visitorLang, type => {
        if (type === 'success') {
          globalActions.setReplayMessage({});
          setOptionMessage(false);
          setAudio({});
          scrollToBottom();
          messageActions.renderMessages([...messages, messageResponse]);
        }
      });
    }
  };
  const uplodPropectionIntoChat = jsx => {
    const messageResponse = handleMessage('Component', jsx, id, user.id);
    if (Object.keys(messageResponse).length > 0) {
      if (Object.keys(replayMessage).length > 0) {
        messageResponse.parent_id = replayMessage.id;
      }
      messageActions.sendMessage(messageResponse, visitorLang, type => {
        if (type === 'success') {
          globalActions.setReplayMessage({});
          setOptionMessage(false);
          setAudio({});
          scrollToBottom();
          messageActions.renderMessages([...messages, messageResponse]);
        }
      });
    }
  };

  const handleClickFile = () => fileInput.current.click();

  const onEmojiClick = (event, emojiObject) => {
    const newValue = `${value}${emojiObject.emoji}`;
    setValue(newValue);
    setShowEmojis(!showEmojis);
  };

  const inviteVisitor = () => {
    socket.emit('SEND_INVITATION', visitorId, user, response => {
      AlertNotification('success', t('alert-message-4'));
    });
  };

  const gifBox = () => (
    <div className="absolute bottom-36 right-16 ">
      <ReactGiphySearchbox
        apiKey={GIPHY_API_KEY}
        onSelect={item => {
          uplodImageIntoChat(item.images.original.url);
          setShowGifs(!showGifs);
        }}
        masonryConfig={[
          { columns: 2, imageWidth: 110, gutter: 5 },
          {
            mq: '700px',
            columns: 3,
            imageWidth: 120,
            gutter: 5,
          },
        ]}
      />
    </div>
  );

  const emojiBox = () => (
    <div className="absolute bottom-36 right-10 ">
      <Picker
        onEmojiClick={onEmojiClick}
        disableAutoFocus
        skinTone={SKIN_TONE_MEDIUM_DARK}
        groupNames={{ smileys_people: 'PEOPLE' }}
        native
      />
    </div>
  );
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#0066b9',
      borderRadius: '30px',
    },
  };

  const buttonStyles = {
    border: 'solid 1px',
    borderRadius: '100px',
    borderColor: '#f7fafc',
    margin: '10px',
    padding: '10px',
    backgroundColor: 'white',
  };

  const buttonStyles2 = {
    border: 'solid 1px',
    borderRadius: '100px',
    borderColor: '#f7fafc',
    margin: '10px',
    padding: '10px',
    backgroundColor: 'green',
    color: 'white',
  };

  const modalStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '100px',
  };

  return (
    <>
      {status && visitorStatus && !isDisabled && visitorOnline && visitorChating ? (
        <div className="flex flex-col flex-1 border-t px-5 py-3 mb-2 sm:mb-0 h-24 justify-center">
          {optionMessage && (
            <>
              <div className="px-2">
                <h1>{handleRenderMessageContent(replayMessage, true)}</h1>
              </div>
              <hr className="border-t py-2" />
            </>
          )}
          <div className="relative flex">
            <div className="items-center w-full inset-y-0 hidden sm:flex mr-3">
              <>
                {Object.keys(shortCode).length > 0 && shortcutView && (
                  <button
                    type="button"
                    onClick={() => {
                      setValue('');
                      setValue(shortCode.content);
                      setShortcutView(false);
                    }}
                    className="bg-transparent outline-none border-none origin-top-right relative left-0 top-0 w-56 rounded-md shadow-lg
                    bg-white ring-1 flex justify-center items-center ring-black ring-opacity-5"
                  >
                    <h3>{shortCode.code}</h3>
                  </button>
                )}
                {!Object.keys(audio).length > 0 && (
                  <input
                    type="text"
                    placeholder="Write Something"
                    className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 mx-4 py-3 font-thin"
                    onKeyDown={sendMessage}
                    value={value}
                    onChange={e => {
                      setValue(e.target.value);
                      handleLoadShortcut(
                        e.target.value,
                        getShortcodes,
                        pageShortcode,
                        setShortCode,
                        setShortcutView,
                      );
                    }}
                  />
                )}
                {showEmojis && emojiBox()}
                {showGifs && gifBox()}
              </>
              <button
                type="button"
                onClick={setModalIsOpenToTrue}
                title="open prospection questions"
                className="items-center justify-center ease-in-out focus:outline-none p-3 flex hover:text-gray-600 w-12 h-12 rounded-full hover:bg-gray-100 cursor-pointer"
              >
                <RiSurveyLine className="w-4 h-4" />
              </button>
              <Modal isOpen={modalIsOpen} style={customStyles} onRequestClose={() => setModalIsOpen(false)} ariaHideApp={false}>
                <div style={modalStyles}>
                  <button type="button" style={buttonStyles2} value={confirmEmail} onClick={e => { uplodPropectionIntoChat(e.target.value); }}>Confirmacion de correo electronico</button>
                  <button type="button" style={buttonStyles} value={prospection6} onClick={e => { uplodPropectionIntoChat(e.target.value); }}>¿Qué prueba de inglés has tomado?</button>
                  <button type="button" style={buttonStyles} value={prospection3} onClick={e => { uplodPropectionIntoChat(e.target.value); }}>¿Cuál es tu nivel actual de estudios?</button>
                  <button type="button" style={buttonStyles} value={prospection4} onClick={e => { uplodPropectionIntoChat(e.target.value); }}>¿Qué tipo de curso te gustaría estudiar?</button>
                </div>
                <div>
                  <button type="button" style={buttonStyles} value={prospection7} onClick={e => { uplodPropectionIntoChat(e.target.value); }}>¿Cuándo deseas iniciar tus estudios?</button>
                  <button type="button" style={buttonStyles} value={prospection5} onClick={e => { uplodPropectionIntoChat(e.target.value); }}>¿Estarias dispuesto a iniciar tu primer periodo de estudio online?</button>
                  <button type="button" style={buttonStyles} value={prospection1} onClick={e => { uplodPropectionIntoChat(e.target.value); }}>¿Cómo planeas financiar tus estudios?</button>
                  <button type="button" style={buttonStyles} value={prospection2} onClick={e => { uplodPropectionIntoChat(e.target.value); }}>¿En qué área te gustaría estudiar?</button>
                </div>
              </Modal>
              <button
                type="button"
                onClick={e => handleClickFile(e)}
                title="send files"
                className="items-center justify-center ease-in-out focus:outline-none p-3 flex hover:text-gray-600 w-12 h-12 rounded-full hover:bg-gray-100 cursor-pointer"
              >
                <FiPaperclip className="w-4 h-4" />
              </button>
              <button
                type="button"
                title="open gifs"
                onClick={() => setShowGifs(!showGifs)}
                className="items-center justify-center ease-in-out focus:outline-none p-3 flex hover:text-gray-600 w-12 h-12 rounded-full hover:bg-gray-100 cursor-pointer"
              >
                <FiHeart className="w-4 h-4" />
              </button>
              <button
                type="button"
                title="open emojis"
                onClick={() => setShowEmojis(!showEmojis)}
                className="items-center justify-center ease-in-out focus:outline-none p-3 flex hover:text-gray-600 w-12 h-12 rounded-full hover:bg-gray-100 cursor-pointer"
              >
                <FiSmile className="w-4 h-4" />
              </button>
              <input
                className="hidden"
                type="file"
                ref={fileInput}
                onChange={e => uploadFileIntoChat(
                  e,
                  id,
                  messageActions,
                  visitorLang,
                  setValue,
                  messages,
                )}
              />
              <span className=" items-center justify-center ease-in-out focus:outline-none flex hover:text-gray-600 w-12 h-12 rounded-full hover:bg-gray-100 p-3 cursor-pointer">
                <FiSend
                  className="w-4 h-4"
                  onClick={e => sendMessage(e)}
                  title="send message"
                />
              </span>
            </div>
            {!isRecording && (
              <>
                {Object.keys(audio).length > 0 && (
                  <div className="pl-5">
                    <div className="flex justify-between items-center">
                      <ReactAudioPlayer
                        src={audio.url}
                        autoPlay={false}
                        controls
                      />
                      <button
                        type="button"
                        className="bg-transparent border-transparent outline-none ml-2"
                        onClick={() => setAudio({})}
                      >
                        <MdClose />
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
            <Audio
              setIsRecording={setIsRecording}
              recording={isRecording}
              setAudio={setAudio}
            />
          </div>
        </div>
      ) : (
        <>
          {!status || isDisabled || !visitorOnline || !visitorStatus ? (
            <div className="flex-col flex-1 border-t px-5 py-3 mb-2 sm:mb-0  justify-center flex">
              <h1 className="font-thin text-gray-600 mx-4 py-3">
                You are seeing the history chat of this user
              </h1>
            </div>
          ) : (
            <div className="flex-col flex-1 border-t px-5 py-3 mb-2 sm:mb-0  justify-center flex">
              <h1 className="font-thin text-gray-600 mx-4 py-3">
                You recently completed a counseling session with this visitor.
                Would you like to start a new conversation?
              </h1>
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white rounded-md py-1 px-3"
                onClick={() => inviteVisitor()}
              >
                Yes, send invitation!
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}

ComposeMessage.defaultProps = {
  isDisabled: false,
};

ComposeMessage.propTypes = {
  replayMessage: PropTypes.objectOf(PropTypes.any).isRequired,
  optionMessage: PropTypes.bool.isRequired,
  messageActions: PropTypes.shape({
    getMessages: PropTypes.func,
    sendMessage: PropTypes.func,
    addMessages: PropTypes.func,
    renderMessages: PropTypes.func,
  }).isRequired,
  globalActions: PropTypes.shape({
    setReplayMessage: PropTypes.func,
  }).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  setOptionMessage: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.any).isRequired,
  visitorLang: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  visitorStatus: PropTypes.bool.isRequired,
  visitorId: PropTypes.string.isRequired,
  visitorOnline: PropTypes.bool.isRequired,
  visitorChating: PropTypes.bool.isRequired,
  getShortcodes: PropTypes.func.isRequired,
  handleRenderMessageContent: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
};
